import React from 'react'
import {Tooltip, OverlayTrigger} from 'react-bootstrap'

const InfoTooltip: React.FC<{message: string}> = ({message}) => (
  <OverlayTrigger placement='right' overlay={<Tooltip>{message}</Tooltip>}>
    <i className='bi bi-info-circle text-dark fs-5 ms-3'></i>
  </OverlayTrigger>
)

export default InfoTooltip
