import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'
import useAppToast from 'src/hooks/useAppToast'
import AppDatePickerRange from '../orders/components/AppDatePickerRange'
import {Spinner} from 'react-bootstrap'
import StatusButton from '../orders/StatusButton'
import {KTIcon, KTSVG} from 'src/marketConnect/helpers'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {
  deleteUserAsync,
  getCustomersAsync,
  loginAsCustomerAsync,
  updateCustomerStatusAsync,
} from 'src/redux/actions/customerAction'
import {CUSTOMER} from 'src/app/_interfaces/customer.interface'
import Select from 'react-select'
import {setSelectedCustomerForReport} from 'src/redux/slices/customerSlice'
import {useFormik} from 'formik'
import {createCustomPlanAsync} from 'src/redux/actions/pricingActions'
import * as yup from 'yup'
import InfoTooltip from '../listing/components/InfoTooltip'

const validationSchema = yup.object().shape({
  // user_id: yup.string().required('Please select a user'),
  product_limit: yup
    .number()
    .typeError('Accepts only numeric value')
    .required('Product limit is a required field')
    .positive('Product limit must be positive number'),
  order_limit: yup
    .number()
    .typeError('Accepts only numeric value')
    .required('Order limit is a required field')
    .positive('Order limit must be positive number'),
  billing_frequency: yup.string().required('Please select billing frequency'),
  price: yup
    .number()
    .typeError('Accepts only numeric value')
    .required('Price limit is a required field')
    .positive('Price limit must be positive number'),
    billed_manually: yup.string().required('This field is required.'),
})

const ManageCustomPlans: FC = () => {
  const dispatch = useAppDispatch()
  const {successToast, warningToast} = useAppToast()
  const [customers, setCustomers] = useState<CUSTOMER[] | []>([])
  const [currentPage, setCurrentPage] = useState(1)
  const {
    customerList,
    totalCustomer,
    customerLoader,
    reportData,
    reportLoader,
    selectedCustomerForReport,
  } = useAppSelector((state) => state.customer)
  
  const [customerListPagination, setCustomerListPagination] = useState({
    page_limit: 10,
    page_num: 1,
    order_by: '-id',
    search: '',
    user_type: 'USER',
    from_date: '',
    to_date: '',
  })

  const {values, isSubmitting, errors, touched, setFieldError , handleSubmit, handleChange, handleBlur} = useFormik(
    {
      initialValues: {
        user_id: '',
        product_limit: '',
        order_limit: '',
        billing_frequency: '',
        price: '',
        billed_manually: '',
      },
      validationSchema: validationSchema,
      validateOnChange: true,
      onSubmit: (values, {setSubmitting, resetForm,  }) => {
        //submit form
        console.log('submitting values', selectedCustomerForReport, values)
        if (!selectedCustomerForReport) {
          warningToast('Please select a user')
          return
        } 
       
        setSubmitting(true)
        dispatch(createCustomPlanAsync({...values, user_id: selectedCustomerForReport?.id}))
          .then((response) => {
            console.log('response of custom plan', response.payload)
            if(response.payload?.status==404){
              warningToast(response?.payload?.statusText)
              setSubmitting(false)
            }

            if(response.payload?.status_code==201){
              successToast(response?.payload?.massage)
              resetForm()
              dispatch(setSelectedCustomerForReport(null))
              setSubmitting(false)
            }

            setSubmitting(false)


          })
          .catch((error) => {
            console.log('error', error)
            warningToast('Plan not created '+ error)
            setSubmitting(false)
          })
      },
    }
  )

  useEffect(() => {
    dispatch(getCustomersAsync({...customerListPagination})).then((res) => {
      setCustomers(res.payload?.data?.data)
    })
  }, [customerListPagination])

  // console.log('errors', touched, errors)

  return (
    <div>
      <>
        <div className='mb-3'>
          <span className='fw-bold text-lg ' style={{fontSize: '16px'}}>
            Add New
          </span>
        </div>

        <div className='card border-0 shadow rounded '>
          <form onSubmit={handleSubmit}>
            <div className='card-body'>
              <div className='row g-4'>
                <div className='col-xl-3 col-lg-4 col-md-6 col-12'>
                  <label className='form-label'>Select Customer</label>
                  <Select
                    placeholder='Select User'
                    value={selectedCustomerForReport}
                    options={customers}
                    required
                    isLoading={customerLoader}
                    getOptionLabel={(item) => (item.name ? item.name + `- ${item.email}` : item.email)}
                    getOptionValue={(item) => item.id + ''}
                    isSearchable
                    styles={{
                      dropdownIndicator: (baseStyles) => {
                        return {...baseStyles, display: 'none'}
                      },
                      indicatorSeparator: (baseStyles) => {
                        return {...baseStyles, display: 'none'}
                      },
                    }}
                    onInputChange={(ev) => {
                      console.log('-------------->>>', ev)
                      if (ev) {
                        dispatch(
                          getCustomersAsync({...customerListPagination, page_num: 1, search: ev})
                        ).then((res) => {
                          setCustomers(res.payload?.data?.data)
                        })
                      }
                    }}
                    onBlur={handleBlur}
                    name='user_id'
                    onChange={(ev) => {
                      if (ev) {
                        dispatch(setSelectedCustomerForReport(ev))
                        setFieldError('react-select-2-input','')
                        setFieldError('user_id','')

                      }
                      // handleChange(ev)
                    }}
                    onMenuScrollToBottom={(ev) => {
                      if (currentPage < totalCustomer / 10) {
                        dispatch(
                          getCustomersAsync({...customerListPagination, page_num: currentPage + 1})
                        ).then((res) => {
                          console.log('res', res.payload?.data?.data?.length)
                          if (res.payload?.data?.data?.length) {
                            setCurrentPage(currentPage + 1)
                          }
                          setCustomers((pre) => [...pre, ...res.payload?.data?.data])
                        })
                      }
                    }}
                  />
                  { !selectedCustomerForReport && touched['react-select-2-input'] && errors.user_id && (
                    <span className='fs-8 text-danger'>{errors?.user_id}</span>
                  )}
                </div>
                <div className='col-xl-3 col-lg-4 col-md-6 col-12'>
                  <label className='form-label'>Billed Manually ?</label> <InfoTooltip message={ <p className='mb-0' >Yes, Show Subscribed Only : No PayPal plan will be created for existing users with manual billing. <br />No, Create PayPal Plan : PayPal plan will be created for new users.</p> } />
                  <select
                    required
                    value={values.billed_manually}
                    name='billed_manually'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='form-select form-select-lg'
                  >
                    <option value=''>Select One</option>
                    <option value='true'>Yes, Show Subscribed Only</option>
                    <option value='false'>No, Create PayPal Plan</option>
                  </select>
                  {touched.billed_manually && errors.billed_manually && (
                    <span className='fs-8 text-danger'>{errors?.billed_manually}</span>
                  )}
                </div>
                <div className='col-xl-3 col-lg-4 col-md-6 col-12'>
                  <label className='form-label'>Product/SKU Limit</label>
                  <input
                    required
                    type='text'
                    value={values.product_limit}
                    placeholder='ex. - 1000'
                    name='product_limit'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='form-control form-control-lg'
                  />
                  {touched.product_limit && errors.product_limit && (
                    <span className='fs-8 text-danger'>{errors?.product_limit}</span>
                  )}
                </div>
                <div className='col-xl-3 col-lg-4 col-md-6 col-12'>
                  <label className='form-label'>Order Limit</label>
                  <input
                    required
                    type='text'
                    value={values.order_limit}
                    placeholder='ex. - 1000'
                    name='order_limit'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='form-control form-control-lg'
                  />
                  {touched.order_limit && errors.order_limit && (
                    <span className='fs-8 text-danger'>{errors?.order_limit}</span>
                  )}
                </div>
                <div className='col-xl-3 col-lg-4 col-md-6 col-12'>
                  <label className='form-label'>Plan Price (USD)</label>
                  <input
                    required
                    type='text'
                    value={values.price}
                    placeholder='ex. - 1299 '
                    name='price'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='form-control form-control-lg'
                  />
                  {touched.price && errors.price && (
                    <span className='fs-8 text-danger'>{errors?.price}</span>
                  )}
                </div>
                <div className='col-xl-3 col-lg-4 col-md-6 col-12'>
                  <label className='form-label'>Billing Frequency</label>
                  <select
                    required
                    value={values.billing_frequency}
                    name='billing_frequency'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='form-select form-select-lg'
                  >
                    <option value=''>Select One</option>
                    <option value='EVERY_30_DAYS'>Monthly</option>
                    <option value='ANNUAL'>Annually</option>
                  </select>
                  {touched.billing_frequency && errors.billing_frequency && (
                    <span className='fs-8 text-danger'>{errors?.billing_frequency}</span>
                  )}
                </div>
              </div>
              <div className='d-flex mt-4 justify-content-end'>
                <button
                  disabled={isSubmitting}
                  type='submit'
                  className='btn btn-sm btn-success fw-bold'
                >
                  {isSubmitting ? 'Saving Plan...' : 'Add Plan'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    </div>
  )
}

export default ManageCustomPlans
