import React from 'react'
import ConnectedPlatforms from './ConnectedPlatformModal' // Correct the import here

const Settings: React.FC = () => {
  return (
    <div className='row'>
      {/* Your ConnectedPlatforms component here */}
      <div className='col-12'>
        <div className='card shadow my-0 p-4'>
          <h4 className='text-primary fs-4 mb-4'>Connected Platforms</h4>
          <ConnectedPlatforms /> {/* No props needed */}
        </div>
      </div>
    </div>
  )
}

export default Settings
