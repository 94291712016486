import React from 'react'

interface NoPlatformAvailableProps {
  message: string
  description: string
}

const NoPlatformAvailable: React.FC<NoPlatformAvailableProps> = ({message, description}) => {
  return (
    <div className='d-flex justify-content-center align-items-center p-5 mb-10'>
      <div className='d-flex flex-column'>
        <h5 className='mb-1'>{message}</h5>
        {/* <span>{description}</span> */}
      </div>
    </div>
  )
}

export default NoPlatformAvailable
