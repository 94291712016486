import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const getUserInvitesListAsync = createAsyncThunk(
  'userInvites/getUserInvitesListAsync',
  async function (
    params: {
      userId: number | undefined
      paramData: {page: number; limit?: number}
    },
    toolkit
  ) {
    return await AxiosClient(
      'GET',
      `/invited-user/?user_id=${params.userId}`,
      [],
      toolkit,
      params.paramData
    )
  }
)

export const postInvitedUser = createAsyncThunk(
  'userInvites/postInvitedUser',
  async function (
    params: {
      userdata: {
        email: string
        name: string
        user_type: string
      }
      userId: number | undefined
    },
    toolkit
  ) {
    return await AxiosClient(
      'POST',
      `/invited-user/?user_id=${params.userId}`,
      params.userdata,
      toolkit,
      []
    )
  }
)

export const deleteInvitedUser = createAsyncThunk(
  'userInvites/deleteInvitedUser',
  async function (
    params:any,
    toolkit
  ) {
    return await AxiosClient(
      'DELETE',
      `/invited-user`,
      [],
      toolkit,
      params
    )
  }
)
