import React, {FC, useState} from 'react'
import {ConnectedPlatform} from 'src/interfaces/Platform.interface' // Import from Platform.interface
import {KTSVG} from 'src/marketConnect/helpers'
import {loggedInAdminAccessToken} from 'src/utils/constants'
import ExpandedSections from './PlatformSettings'

type ConnectedPlatformStripe = {
  platform: ConnectedPlatform
  onDelete: () => void
}

const ConnectPlatformBox: FC<ConnectedPlatformStripe> = ({platform, onDelete}) => {
  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({
    isCreateOrders: false,
    isImportOrders: false,
    isImportShipments: false,
    isCreateShipments: false,
  })

  const toggleAllSections = () => {
    const allOpen = Object.values(expandedSections).every((value) => value)
    setExpandedSections({
      isCreateOrders: !allOpen,
      isImportOrders: !allOpen,
      isImportShipments: !allOpen,
      isCreateShipments: !allOpen,
    })
  }

  const [formValues, setFormValues] = useState<{
    source_platform: string
    shipment_setting: {
      isCreateOrders: boolean
      isImportOrders: boolean
      isImportShipments: boolean
      isCreateShipments: boolean
    }
    order_setting: {
      sync_start_date: string
    }
  }>({
    source_platform: '',
    shipment_setting: {
      isCreateOrders: false,
      isImportOrders: false,
      isImportShipments: false,
      isCreateShipments: false,
    },
    order_setting: {
      sync_start_date: '2024-12-01T12:00',
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = event.target
    if (type === 'checkbox') {
      setFormValues((prevValues) => ({
        ...prevValues,
        shipment_setting: {
          ...prevValues.shipment_setting,
          [name]: checked,
        },
      }))
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        order_setting: {
          ...prevValues.order_setting,
          [name]: value,
        },
      }))
    }
  }

  return (
    <div className='card shadow px-4 py-2 mb-4'>
      <div
        className='card-title d-flex align-items-center justify-content-between w-100 mb-0'
        style={{gap: '16px'}}
      >
        {/* Left Aligned - Title */}
        <h4 className='text-primary fs-6 mb-0 text-left' style={{flex: 1}}>
          {platform.platform_info.type}
        </h4>

        {/* Center Aligned - Configure Settings */}
        <span className='text-center flex-fill text-primary fw-bold' style={{fontSize: '10px'}}>
          Configure Settings
        </span>

        {/* Right Aligned - Delete Icon */}
        <i
          className='bi bi-trash3-fill fs-5 cursor-pointer text-end'
          style={{color: '#ba3232e3', flex: 1}}
          onClick={onDelete}
        ></i>
      </div>

      <div
        className='d-flex align-items-center justify-content-between rounded position-relative mb-0'
        style={{gap: '16px'}}
      >
        {/* Left Aligned - Platform Info (Image + Name) */}
        <div className='d-flex align-items-center justify-content-start' style={{flex: 1}}>
          <img
            src={platform.platform_info.logo_ref}
            alt='Channel Logo'
            className='me-2'
            style={{width: '40px', height: '40px'}}
          />
          <h4 className='title mb-0 fs-5'>{platform.display_name}</h4>
        </div>

        {/* Center Aligned - Button */}
        <div
          className='d-flex align-items-center justify-content-center'
          style={{flex: 1, textAlign: 'center'}}
        >
          <button
            onClick={toggleAllSections}
            className='cursor-pointer border-0 bg-transparent p-0'
          >
            <KTSVG
              path='media/icons/duotune/coding/cod001.svg'
              className='svg-icon-muted svg-icon-2hx'
            />
          </button>
        </div>

        {/* Right Aligned - Connection Status */}
        <div className='d-flex align-items-center justify-content-end' style={{flex: 1}}>
          <span className='text-success fw-bold fs-5'>
            <i className='bi bi-wifi text-success fs-3 fw-bold'></i>
          </span>
        </div>
      </div>

      <ExpandedSections
        expandedSections={expandedSections}
        setExpandedSections={setExpandedSections}
        formValues={formValues}
        setFormValues={setFormValues}
        handleChange={handleChange}
      />
    </div>
  )
}

export default ConnectPlatformBox
