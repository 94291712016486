import React, { useEffect } from "react";

export function TwakScript() {
    useEffect(() => {
      // Start of Tawk.to Script
      if (process.env['REACT_APP_ENV'] === 'production') {
      const script = document.createElement("script");
      script.async = true;
      script.src = 'https://embed.tawk.to/660663bda0c6737bd126275f/1hq4fb753';
      script.charset = 'UTF-8';
      script.setAttribute('crossorigin', '*');
      document.body.appendChild(script);

    //   return () => {
    //     document.body.removeChild(script);
    //   };
    }
    }, []);

  return null;
}