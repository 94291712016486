import React, { useState } from 'react';
import Card from './Card'

const CRMPlatforms = ({platforms}: {platforms: any[]}) => {
  const [selectedPlatformId, setSelectedPlatformId] = useState<string | undefined>(undefined);
  return (
    <div className='row'>
      {platforms.map((platform) => (
        <Card
          key={platform.id}
          data={platform}
          setSelectedPlatformId={setSelectedPlatformId}  // Pass setSelectedPlatformId to Card
          onClickConnectTop={(connectState) => {
            console.log('Connection State:', connectState)
          }}
        />
      ))}
    </div>
  )
}

export default CRMPlatforms
