/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from 'src/marketConnect/helpers'
import TrustBox from './TrustBox'
import { Carousel } from 'react-bootstrap'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100 '>
      {/* begin::Body */}
      <div
        className='d-flex flex-column flex-lg-row-fluid w-lg-50  order-2 order-lg-1 overflow-lg-scroll'
        style={{scrollbarWidth: 'none'}}
      >
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px w-md-400px w-300px py-4 px-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
          <div className='d-flex fw-semibold text-primary fs-base mb-2'>
            <a href='https://constacloud.com/tou.html' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='https://constacloud.com/privacy-policy.html' className='px-5' target='_blank'>
              Policy
            </a>

            <a href='https://constacloud.com/contact.html' className='px-sm-5 px-2' target='_blank'>
              Contact us
            </a>
          </div>
          {/* end::Links */}
        </div>

        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 overflow-lg-scroll'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`,
          scrollbarWidth: 'none',
        }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100 '>
          {/* begin::Logo */}
          <Link to='/' className=''>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/commercium-logo-white.png')}
              className='h-sm-85px h-50px'
            />
          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          {/* <img
            className='mx-auto w-275px w-md-50 w-xl-300px mb-10 mb-lg-5'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          /> */}
          <div className='mt-4' >
          <Carousel color='success' controls={false}  >
              <Carousel.Item>
                  <div className='mx-auto w-300px w-md-75 w-xl-350px mb-10 mb-lg-5 bg-primary shadow text-white p-4 rounded '>
                    <div className='d-flex align-items-center '>
                      <div className='h-40px w-40px rounded-circle bg-warning text-white fs-4 fw-bold d-flex align-items-center justify-content-center'>
                        SO
                      </div>
                      <div className='ms-2'>
                        <p className='mb-0 lh-sm'><b>Sophie</b></p>
                        <small className='mb-0 lh-sm'>1 review</small>
                      </div>
                      <p className='ms-2 mb-0'>
                        <i className='bi bi-geo-alt text-white'></i>&nbsp;
                        <span>LU</span>
                      </p>
                    </div>
                    <img src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg" className='h-20px mt-3 mb-2' alt="" />
                    <div className='min-h-250px'>
                      <b>Outstanding Support Team</b>
                      <p className='lh-sm fs-7'>
                        We are thrilled with our experience using this tool and the support team behind it.
                        Their responsiveness is simply exceptional—every question or specific need is
                        handled with care and speed. You can truly feel that they are committed to providing
                        impeccable service and ensuring everything runs smoothly. <br />&nbsp;&nbsp;&nbsp;The tool itself is
                        efficient and intuitive, which has allowed us to streamline many processes. It saved
                        us valuable time, all of this comes at a very reasonable price. We highly recommend
                        this tool and the dedicated team supporting it.
                      </p>
                      <blockquote className='fw-semibold text-end'>December 10, 2024</blockquote>
                    </div>
                  </div>
              </Carousel.Item>
              <Carousel.Item>
                  <div className='mx-auto w-300px w-md-75 w-xl-350px mb-10 mb-lg-5 bg-primary shadow text-white p-4 rounded ' style={{minHeight:'368px'}} >
                    <div className='d-flex align-items-center '>
                      <div className='h-40px w-40px rounded-circle bg-light-danger text-secondary fs-4 fw-bold d-flex align-items-center justify-content-center'>
                        YO
                      </div>
                      <div className='ms-2'>
                        <p className='mb-0 lh-sm'><b>Ye Olde Friars</b></p>
                        <small className='mb-0 lh-sm'>5 review</small>
                      </div>
                      <p className='ms-2 mb-0'>
                        <i className='bi bi-geo-alt text-white'></i>&nbsp;
                        <span>GB</span>
                      </p>
                    </div>
                    <img src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg" className='h-20px mt-3 mb-2' alt="" />
                    <div className=''>
                      <b>NOTHS and Shopify Integration</b>
                      <p className='lh-sm fs-7'>
                      We needed an integration for Not On the High Street and Shopify and this woked perfectly. Aside from it being technically spot on the customer service is excellent. They're quick to reply and seem to genuinely care if it's working for you or not.
                      </p>
                      <blockquote className='fw-semibold text-end'>November 29, 2024</blockquote>
                    </div>
                  </div>
              </Carousel.Item>
              <Carousel.Item>
                  <div className='mx-auto w-300px w-md-75 w-xl-350px mb-10 mb-lg-5 bg-primary shadow text-white p-4 rounded ' style={{minHeight:'368px'}} >
                    <div className='d-flex align-items-center '>
                      <div className='h-40px w-40px rounded-circle bg-secondary text-white fs-4 fw-bold d-flex align-items-center justify-content-center'>
                        TH
                      </div>
                      <div className='ms-2'>
                        <p className='mb-0 lh-sm'><b>Thomas</b></p>
                        <small className='mb-0 lh-sm'>1 review</small>
                      </div>
                      <p className='ms-2 mb-0'>
                        <i className='bi bi-geo-alt text-white'></i>&nbsp;
                        <span>AU</span>
                      </p>
                    </div>
                    <img src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg" className='h-20px mt-3 mb-2' alt="" />
                    <div className=''>
                      <b>They are really good at there customer…</b>
                      <p className='lh-sm fs-7'>
                        They are really good at there customer support and are very responsive.
                      </p>
                      <blockquote className='fw-semibold text-end'>December 04, 2024</blockquote>
                    </div>
                  </div>
              </Carousel.Item>
          </Carousel>
          </div>
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            {/* Fast, Efficient and Productive */}
          </h1>
          <TrustBox />
          <div className='d-flex justify-content-center mt-6 mb-1 position-lg-absolute bottom-0 mb-4'>
            <div className='text-white order-2 order-md-1 '>
              <span className='text-white  fw-semibold me-1 fw-bold'>
                {new Date().getFullYear().toString()}&copy;
              </span>
              <a href='https://constacloud.com/' target='_blank' className='text-white fw-bold'>
                ConstaCloud Private Limited
              </a>
            </div>
          </div>
          {/* end::Title */}

          {/* begin::Text */}
          {/* <div className='text-white fs-base text-center'>
            In this kind of post,{' '}
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the blogger
            </a>
            introduces a person they’ve interviewed <br /> and provides some background information
            about
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the interviewee
            </a>
            and their <br /> work following this is a transcript of the interview.
          </div> */}
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
