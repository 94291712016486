import {FC, Fragment, useState} from 'react'
import InfoTooltip from '../InfoTooltip'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import {useAppSelector} from 'src/redux/storeHooks'

const ShortCodeInput: FC<{
  label: string
  message: string
  inputWrapperClasses?: string
  containerClasses?: string
  labelClass?: string
}> = ({
  label = '',
  message = '',
  inputWrapperClasses = 'input-group mb-3',
  containerClasses = 'col-12 col-sm-6 mt-4',
  labelClass = 'form-label',
}) => {
  const [isShortCodeVisible, setIsShortCodeVisible] = useState(false)
  const [shortCode, setShortCode] = useState(['[ORDER_ID]', '[ORDER_NUMBER]'])
  const [copyMarkup, setCopyMarkup] = useState(false)
  const [buttonString, setButtonString] = useState('')
  const [inputValue, setInputValue] = useState('') // Local state for input
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const {retrivedPlatformData} = useAppSelector((state) => state.retrivePlatformData)

  const handleCopyMarkup = (data: string) => {
    setCopyMarkup(true)
    setButtonString(data)
    setTimeout(() => {
      setCopyMarkup(false)
      setButtonString('')
    }, 1000)
  }

  const renderTooltip = (shortcode: string) => {
    let tooltipText = ''
    switch (shortcode) {
      case '[ORDER_ID]':
        tooltipText = ` ${mainStoreData?.code} Auto Generated`
        break
      case '[ORDER_NUMBER]':
        tooltipText = `Order Number from ${retrivedPlatformData?.code}`
        break
      default:
        tooltipText = ''
    }
    return <Tooltip id={`${shortcode}-tooltip`}>{tooltipText}</Tooltip>
  }

  return (
    <div className={containerClasses}>
      <label className='form-label mb-0'>
        {label} &nbsp;&nbsp;
        <InfoTooltip message={message} />
      </label>
      <div className='my-2'>
        <input
          className='form-control'
          type='text'
          value={inputValue} // Local state for input
          onChange={(e) => setInputValue(e.target.value)} // Update state on input change
        />
      </div>

      <div className='text-end'>
        <button
          type='button'
          className='btn btn-link text-decoration-none text-primary fw-bold mt-2 p-0'
          onClick={() => setIsShortCodeVisible(!isShortCodeVisible)}
        >
          <FontAwesomeIcon icon={faPlus} className='me-2' />
          See the Options
        </button>
      </div>

      {isShortCodeVisible && (
        <div className='d-flex justify-content-end'>
          {shortCode.map((ele: string) => (
            <Fragment key={ele}>
              <OverlayTrigger placement='bottom' overlay={renderTooltip(ele)}>
                <CopyToClipboard text={ele}>
                  <Button
                    size='sm'
                    variant={
                      copyMarkup && buttonString === ele ? 'outline-success' : 'outline-primary'
                    }
                    className={`px-3 m-1 ${
                      copyMarkup && buttonString === ele ? 'border-success' : 'border-primary'
                    }`}
                    onClick={() => handleCopyMarkup(ele)}
                  >
                    {copyMarkup && buttonString === ele ? 'Copied!' : ele}
                  </Button>
                </CopyToClipboard>
              </OverlayTrigger>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

export default ShortCodeInput
