import React, { FC, useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import { refreshPlatformDataAsync} from 'src/redux/actions/platformActions'
import { PlatformDataType } from 'src/interfaces/Platform.interface'
import useAppToast from 'src/hooks/useAppToast'
import Loader from 'src/utils/Loader'
import {Link} from 'react-router-dom'
import TemaplateComponent from './components/TemaplateComponent'
import { KTSVG } from 'src/marketConnect/helpers'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import clsx from 'clsx'



const TemplateAndPricing:FC = () => {

  const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false)
  const [refreshPlatformId, setRefreshPlatformId] = useState<number|null>(null)
  const [refreshing, setRefreshing] = useState(false)
  const [currentActiveTab, setCurrentActiveTab] = useState(1)
  const {fetchedConnectedPlatformData, fetchedConnectedPlatformLoading} = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )
  const [secondaryStorePlatforms, setSecondaryStorePlatforms] = useState<PlatformDataType[]>([])
  const { successToast } = useAppToast()
  const dispatch = useAppDispatch()
  const {
    platformPricingTemplateLoading,
  } = useAppSelector((state) => state.platformRepricing)


  useEffect(() => {
    if (fetchedConnectedPlatformData && fetchedConnectedPlatformData.length > 0) {
      const secondaryStore: any = fetchedConnectedPlatformData.filter(
        (platform) => !platform.isMainStore
      )
      setSecondaryStorePlatforms(secondaryStore)
    }
  }, [fetchedConnectedPlatformData])


  return (
    <>
      <h3>Listing Rules And Plan</h3>
      {/* <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 mt-2'>
        <li className='breadcrumb-item'>
          <span className='bullet bg-gray-500 w-5px h-2px'></span>
        </li>
        <li className='breadcrumb-item px-3 text-muted text-hover-primary'>Template and Pricing</li>
      </ul> */}

      <div className='py-2 '>
        <div className={ secondaryStorePlatforms.length==1 ? '' : 'px-md-7 px-4  py-2 shadow'}>
          {fetchedConnectedPlatformLoading ? (
            <div className='d-flex align-items-center justify-content-center h-100'>
              <h1>
                <Loader />
              </h1>
            </div>
          ) : (
            <div>
              <ul className="nav nav-tabs nav-line-tabs fs-6 d-flex flex-nowrap overflow-x-scroll min-w-100 overflow-y-hidden" style={{scrollbarWidth:'none', marginTop:'10px'}} >
              {secondaryStorePlatforms.length ? 
                secondaryStorePlatforms.map((item,index) => {
                  if( (process.env['REACT_APP_ENV'] == 'production' && ['SHOPIFY','DSCO'].includes(item?.code) ) || ['SUPERPHARM','CATCH','MIRAKL','DEBENHAMS','DSCO'].includes(item?.code) ){
                    return null
                  }else{
                    return(
                      <li key={'tab-items'+index} className="nav-item m-0">
                        <button
                        onClick={()=>setCurrentActiveTab(index+1)}
                          className={clsx("nav-link pb-0 mx-0",
                            currentActiveTab===(index+1)?'active customActive border-2 border-top border-start border-end' :'shadow-none border-2 border-top border-start border-end'
                          )}
                          // data-bs-toggle="tab"
                          // href={"#kt_tab_pane_"+(index+1)}
                        >
                          <div className={currentActiveTab===(index+1) ? 'px-4 py-2' : 'p-2 px-4'} >
                            <img src={item.platform_info?.logo_ref} className='h-40px w-40px' alt={'platform'+index} />
                          </div>
                        </button>
                      </li>
                    )
                  }
            }) : 
              <div className="w-100 alert bg-light-primary d-flex align-items-center p-5 shadow-sm">
                <span className="svg-icon svg-icon-2hx svg-icon-primary me-8">
                  <KTSVG path="media/icons/duotune/communication/com009.svg" className="svg-icon-muted svg-icon-3hx" />
                </span>
          
                <div className="d-flex flex-column">
                  <h5 className="mb-1">No Rules and Plans yet </h5>
                  <span>Please connect a platform. Click here to connect <Link to={'/dashboard'} > <KTSVG path="media/icons/duotune/general/gen016.svg" className="svg-icon-primary svg-icon-hx" /></Link>  </span>
                </div>
              </div>
              }
              </ul>
              <div className="tab-content" id="myTabContent">
                  {secondaryStorePlatforms.length ? 
                      secondaryStorePlatforms.map((item,index) => {
                        if( (process.env['REACT_APP_ENV'] == 'production' && ['SHOPIFY','DSCO'].includes(item.code) ) || ['SUPERPHARM','CATCH','MIRAKL','DEBENHAMS','DSCO'].includes(item?.code) ){
                          return null
                        }else{
                          return(
                              <div
                                key={'tab_data'+index}
                                className={clsx("tab-pane", currentActiveTab===(index+1) ? "active show customTabBody shadow-sm" : '' )}
                                // id={"kt_tab_pane_"+(index+1)}
                                // role={"tabpanel"}
                              >
                                 <TemaplateComponent
                                    key={item.display_name}
                                    item={item}
                                    platformPricingTemplateLoading={platformPricingTemplateLoading}
                                    setShowRefreshModal={setShowRefreshModal}
                                    setRefreshPlatformId={setRefreshPlatformId}
                                  />
                              </div>
                          )
                        }
                      }) : 
                      null 
                  }
              </div>
            </div>
          )}
        </div>
      </div>
      <CommonAlertModal
        key={'reffresh data modal'}
        isVisible={showRefreshModal}
        successDisabled={refreshing}
        onCancel={()=>{
          setShowRefreshModal(false)
          setRefreshPlatformId(null)
        }}
        onSuccess={()=>{
          if(refreshPlatformId){
            setRefreshing(true)
            dispatch(refreshPlatformDataAsync({platformId:refreshPlatformId}))
            .then((response)=>{
              successToast(response.payload?.data?.message? response.payload?.data?.message : JSON.stringify(response.payload?.data?.message) )
              setRefreshing(false)
              setRefreshPlatformId(null)
              setShowRefreshModal(false)
            })
          }
        }}
        title='Are you sure ?'
        subtitle='This might take 10-15 minutes to complete'
        isTermsVisible={false}
      />
    </>
  )
}
export default TemplateAndPricing
