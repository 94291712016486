import {AsyncThunkConfig, GetThunkAPI} from '@reduxjs/toolkit/dist/createAsyncThunk'
import axios from 'axios'
import React, {FC} from 'react'
import { accessSessionTokenKeyName } from 'src/utils/constants'

type REQUEST_METHODS = 'GET' | 'POST' | 'PUT' | 'DELETE'
type ENDPOINT = string
// type TOOLKIT = any
type PARAMS = Object
// type HEADER_OPTIONS = any

const getTokenSync = () =>{
    return localStorage.getItem(accessSessionTokenKeyName)
}

const AxiosClientForFile = async (
  method: REQUEST_METHODS,
  endPoint: ENDPOINT,
  payload: any,
  toolkit: any,
  params: PARAMS,
) => {

  const token = getTokenSync()
  console.log('token', token)
  
  try {
    const response = await axios({
      url: process.env.REACT_APP_API_URL + endPoint,
      method: method,
      data: payload,
      params: { ...params },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return toolkit.fulfillWithValue(response.data);
  }catch(e){
    console.log("error:",e)
  }
  // } catch (error:any) {
  //   // Extract only necessary serializable parts from the error response
  //   const serializableError = {
  //     status: error.response?.status,
  //     data: error.response?.data,
  //   };

  //   return toolkit.rejectWithValue(serializableError);
  // }
};

const publicAxiosClient = async (method: REQUEST_METHODS, endPoint: ENDPOINT, payload: any) => {
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const response = await axios({
      url: process.env.REACT_APP_API_URL + endPoint,
      method: method,
      data: payload,
      headers,
    });

    return response.data; // Return raw data
  } catch (error) {
    console.error('error:', error);
    // Handle errors appropriately
  }
};



const AuthenticatedAxiosClient = async (
  method: string,
  endPoint: string,
  payload: any,
  toolkit: any,
  params: any
) => {
  const token = getTokenSync();

  try {
    const response = await axios({
      url: process.env.REACT_APP_API_URL + endPoint,
      method: method,
      data: payload,
      params: { ...params },
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': `multipart/form-data; boundary=----WebKitFormBoundaryABXgBVOhBH3HlWGY`

      },
    });
    return toolkit.fulfillWithValue(response.data);
  } catch (error:any) {
    console.error('Error with authenticated request:', error);
    return toolkit.rejectWithValue(error.response)
  }
};



export { publicAxiosClient,AuthenticatedAxiosClient ,AxiosClientForFile};
export default AxiosClientForFile;





