import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from 'src/marketConnect/helpers'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Platforms from '../../../listing/Platforms'
import {Spinner} from 'react-bootstrap'
import Loader from 'src/utils/Loader'
import {Platform, PlatformDataType} from 'src/interfaces/Platform.interface'
import useAppToast from 'src/hooks/useAppToast'
import {useNavigate} from 'react-router-dom'
import {
  connectPlatformAsync,
  deletePlatformDataAsync,
  fetchConnectedPlatformAsync,
  fetchPlatformsAsync,
  shopifyappUninstallAsync,
} from 'src/redux/actions/platformActions'
import ConnectPlatformModal from '../ConnectPlatformModal'
import PlatformCard from '../PlatformCard'
import clsx from 'clsx'
import { setStepsData } from 'src/redux/slices/tourPopupSlice'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import { fetchSkipSetupAsync, fetchUserAccountDetailsAsync } from 'src/redux/actions/accountActions'
type StepStatus = 'active' | 'inactive' | 'finished'

type TourSteps = {
  stepOne: StepStatus
  stepTwo: StepStatus
  stepThree: StepStatus
}

type TourStep1PropType = {
  // setStepsData: React.Dispatch<React.SetStateAction<TourSteps>>
  setSelectedPlatformId: React.Dispatch<React.SetStateAction<string | undefined>>
}

const TourStep1: FC<TourStep1PropType> = ({ setSelectedPlatformId}) => {
  const {fetchedConnectedPlatformData, fetchedConnectedPlatformLoading} = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )
  
  const {fetchedUserAccountData} = useAppSelector((state) => state.userAccountData)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {platformData} = useAppSelector((state) => state.platform)
  const {stepsData} = useAppSelector((state) => state.tourPopup)
  const [showConnectPlatformModal, setShowConnectPlatformModal] = useState<boolean>(false)
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(null)
  const [ecommercePlatform, setEcommercePlatform] = useState<any>([])
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false)
  const [isSkipping, setIsSkipping] = useState<boolean>(false)
  const [modalData, setModalData] = useState<PlatformDataType>()
  const [confirmChannelDelete, setConfirmChannelDelete] = useState<boolean>(false)
  const [confirmSkipOnboarding, setConfirmSkipOnboarding] = useState<boolean>(false)

  const [deleting, setDeleting] = useState(false)
  const {successToast, warningToast, errorToast} = useAppToast()
  const [connectingPlatformId, setConnectingPlatformId] = useState<{
    platformId: number | string | null
    isConnecting: boolean
  }>({isConnecting: false, platformId: null})
   

  useEffect(() => {
    if (fetchedConnectedPlatformData.length === 0) {
      let newPlatformData = platformData?.filter((ele) =>
        ['ECOMMERCE', 'SHIPPING_PLATFORM'].includes(ele.type)
      )
      setEcommercePlatform(newPlatformData)
    } else {
      setEcommercePlatform(platformData)
    }
  }, [])

  return (
    <>
    { fetchedConnectedPlatformData?.length ?
     <div className="alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row p-3 d-flex justify-content-between">
        <div className='d-flex' >
          <span className="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0">
              <img src={fetchedConnectedPlatformData[0]?.platform_info?.logo_ref!} className="h-40px" />
          </span>
          <div className="d-flex flex-column text-light pe-0 pe-sm-10">
            <h5 className="mb-1 text-success">{fetchedConnectedPlatformData[0] ? fetchedConnectedPlatformData[0]?.display_name : '' }</h5>
            <span className='text-success' >{ fetchedConnectedPlatformData[0]?.isMainStore ?  'Primary Channel Connected' : 'Secondary Channel Conneted' }</span>
          </div>
        </div>
          <div className='d-flex justify-content-center align-items-center' >
            {
              ['COMMERCIUM','WOOCOMMERCE'].includes(fetchedUserAccountData?.source!) ? 
              <button className='btn btn-sm btn-outline border-0 fw-bold text-white py-2' title='Disconnect the channel' onClick={()=>setConfirmChannelDelete(true)} >
                <i className="bi bi-trash-fill fs-3 text-danger"></i>
              </button> 
              : null
            }
            <span className="svg-icon svg-icon-2x svg-icon-light ms-2" title='' >
              <KTSVG path="media/icons/duotune/general/gen043.svg" className="svg-icon-success svg-icon-2hx" />
            </span>
          </div>
      </div> 
    : null }
      <div>
      <div className='p-2 px-5 rounded bg-light-info my-2 fs-5 mb-4' >
        <i className="bi bi-telephone-fill fw-bold text-primary fs-5"></i> Book a call <a className='fw-bold' target='_blank'  href="https://cal.com/team/constacloud-private-limited">here</a>,  We'll set it up for free!  
      </div>
        <div className='d-flex align-items-center justify-content-between' >
          <h4>
            {connectingPlatformId.isConnecting
              ? ''
              : 'Available Primary Channels'}
          </h4>
          {
            ['COMMERCIUM','WOOCOMMERCE'].includes(fetchedUserAccountData?.source!) ?
            <div style={{display:"flex", gap:10}}>
                <button
                  className='btn btn-sm btn-primary fs-6 fw-bold'
                  onClick={()=>setConfirmSkipOnboarding(true)}
                >
                  Skip Setup
                </button>
            <button type='button' className='btn btn-sm btn-primary fs-6 fw-bold' 
              onClick={()=>{
                  dispatch(setStepsData({stepData:stepsData,visible:false}))
                  navigate('/pricing')
                }} > <i className={'bi bi-coin fs-4 me-2'}></i> Manage Plan</button></div>
            : null
          }
        </div>
      </div>
      <div className='py-4'>
        <div
          className={clsx(
            'row g-3 gx-2 g-xl-8 ',
            connectingPlatformId.isConnecting ? 'justify-content-center' : ''
          )}
        >
          {ecommercePlatform?.length > 0 &&
            ecommercePlatform.map((item: Platform) => {
              if (connectingPlatformId.isConnecting) {
                if (connectingPlatformId.platformId === item.id) {
                  return (
                    <PlatformCard
                      key={item.id}
                      data={item}
                      onClickConnectTop={setConnectingPlatformId}
                      // setStepsData={setStepsData}
                      setSelectedPlatformId={setSelectedPlatformId}
                    />
                  )
                }
              } else {
                return (
                  <PlatformCard
                    key={item.id}
                    data={item}
                    onClickConnectTop={setConnectingPlatformId}
                    // setStepsData={setStepsData}
                    setSelectedPlatformId={setSelectedPlatformId}
                  />
                )
              }
            })}
        </div>
      </div>
      {/* { showConnectPlatformModal ? 
        <ConnectPlatformModal
        isVisible={showConnectPlatformModal}
        closeModal={handleCloseModal}
        data={selectedPlatform}
        onDataReceived={handleDataReceived} // Pass the data receiving function
      /> : null } */}
      <CommonAlertModal
        key={'Delete Channel Confirmation'}
        title={'Are you sure, you want to disconnect the connected channel?'}
        modalClassName='custmd'
        subtitle=''
        successDisabled={deleting}
        isTermsVisible={false}
        successBtnTitle={ deleting ? 'Disconnecting...' : 'Yes Disconnect'}
        isVisible={confirmChannelDelete}
        onCancel={() => {
          setConfirmChannelDelete(false)
        }}
        onSuccess={async () => {
          // setConfirmChannelDelete(false)
          if (fetchedConnectedPlatformData[0]?.id) {
            setDeleting(true)
            //uninstall shopify 
            if(fetchedUserAccountData?.source==="SHOPIFY"){
                const response = await dispatch(shopifyappUninstallAsync({id: fetchedConnectedPlatformData[0]?.id }))
                if(response.payload==200){
                  await dispatch(fetchConnectedPlatformAsync())
                  successToast('Channel disconnected successfully!')
                  dispatch(setStepsData({stepData:{stepOne: 'active', stepTwo: 'inactive', stepThree: 'inactive'},visible:true}))
                }else{
                  warningToast('Channel not disconnected '+ " " +  response.payload?.massage)
                }
                setDeleting(false)
                setConfirmChannelDelete(false)
            }else{
              await dispatch(deletePlatformDataAsync({id: fetchedConnectedPlatformData[0]?.id }))
              .then(async(res)=>{
                
                if(res.payload?.status_code==200){
                  await dispatch(fetchConnectedPlatformAsync())
                  successToast('Channel disconnected successfully!')
                  dispatch(setStepsData({stepData:{stepOne: 'active', stepTwo: 'inactive', stepThree: 'inactive'},visible:true}))
                }else{
                  warningToast('Channel not disconnected'+" "+res.payload?.massage)
                }
              })
              .catch((err)=>{
                errorToast('Channel not deleted'+ err)
              })
              setDeleting(false)
              setConfirmChannelDelete(false)
      
            }
            
            // setDeletionCompleted(true) // Update deletionCompleted state
          } else {
            console.error('Platform id is undefined.')
            warningToast('Platform id is undefined.')
          } 
        }}
      />

      <CommonAlertModal
        key={'Skip Onboarding Confirmation'}
        title={'This Onboarding process guide you to do setup by showing steps, Are you sure you want to skip this & explore on your own?'}
        subtitle=''
        isTermsVisible={false}
        successDisabled={isSkipping}
        successBtnTitle={isSkipping? "Updating...": 'Yes, Skip it.'}
        cancelBtnTitle={'Cancel'}
        showCloseButton={true}
        modalClassName='custmd'
        isVisible={confirmSkipOnboarding}
        onCancel={() => {
          setConfirmSkipOnboarding(false)
        }}
        onSuccess={async() => {
          setIsSkipping(true)
            const response = await dispatch(fetchSkipSetupAsync());
            if (response?.payload?.status_code === 200) {
              dispatch(fetchUserAccountDetailsAsync());
              navigate("/dashboard");
          }
        }}
      />
    </>
  )
}

export default TourStep1
