import React, {FC, useState} from 'react'
import {Platform} from 'src/interfaces/Platform.interface'
import ConnectPlatformModal from './PlatformModal'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {useNavigate} from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import {connectPlatformAsync, fetchConnectedPlatformAsync} from 'src/redux/actions/platformActions'
import {setStepsData} from 'src/redux/slices/tourPopupSlice'

type StepStatus = 'active' | 'inactive' | 'finished'

type TourSteps = {
  stepOne: StepStatus
  stepTwo: StepStatus
  stepThree: StepStatus
}

type PlatformCardPropType = {
  data: Platform
  setSelectedPlatformId: React.Dispatch<React.SetStateAction<string | undefined>>
  onClickConnectTop: React.Dispatch<
    React.SetStateAction<{platformId: number | string | null; isConnecting: boolean}>
  >
}

const Card: FC<PlatformCardPropType> = ({data, onClickConnectTop, setSelectedPlatformId}) => {
  const {fetchedConnectedPlatformData, fetchedConnectedPlatformLoading} = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )
  const {fetchedUserAccountData} = useAppSelector((state) => state.userAccountData)
  const {successToast, warningToast, errorToast} = useAppToast()
  const dispatch = useAppDispatch()
  const [showConnectPlatformModal, setShowConnectPlatformModal] = useState<boolean>(false)
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(null)
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false)

  const onClickConnect = (platform: Platform) => {
    setSelectedPlatform(platform)
    setShowConnectPlatformModal(true)
  }

  const handleCloseModal = () => {
    setShowConnectPlatformModal(false)
    setSelectedPlatform(null)
    onClickConnectTop({platformId: null, isConnecting: false})
  }

  const handleDataReceived = (dataCollector: any, friendlyName: string) => {
    if (dataCollector['friendlyName'] === '') {
      warningToast('Channel name is required')
      return
    }

    if (selectedPlatform) {
      let isMainStore =
        fetchedConnectedPlatformData.length === 0 ||
        (fetchedConnectedPlatformData.length === 1 &&
          fetchedConnectedPlatformData[0].isMainStore === false)

      const payload = {
        name: selectedPlatform.name,
        type: selectedPlatform.type,
        code: selectedPlatform.name,
        display_name: friendlyName,
        platform_info: selectedPlatform.id,
        credentials: {
          identifier: dataCollector.find((item: any) => item.name === 'identifier')?.value || null,
          client_id: dataCollector.find((item: any) => item.name === 'client_id')?.value || null,
          client_secret:
            dataCollector.find((item: any) => item.name === 'client_secret')?.value || null,
          token_id: dataCollector.find((item: any) => item.name === 'token_id')?.value || null,
          token_secret:
            dataCollector.find((item: any) => item.name === 'token_secret')?.value || null,
          refresh_token: null,
          sftp_hostname:
            dataCollector.find((item: any) => item.name === 'sftp_hostname')?.value || null,
          sftp_username:
            dataCollector.find((item: any) => item.name === 'sftp_username')?.value || null,
          sftp_password:
            dataCollector.find((item: any) => item.name === 'sftp_password')?.value || null,
          country: dataCollector.find((item: any) => item.name === 'country')?.value || '',
          proxies: dataCollector.find((item: any) => item.name === 'proxies')?.value || '',
          currency: dataCollector.find((item: any) => item.name === 'currency')?.value || '',
        },
        isConnected: true,
        isActive: true,
        isMainStore,
        isOrderSyncProcessing: false,
        isNewOrderSyncProcessing: false,
        isOrderCreateProcessing: false,
      }

      dispatch(connectPlatformAsync(payload)).then((response) => {
        dispatch(fetchConnectedPlatformAsync())
        if (response.meta.requestStatus === 'fulfilled') {
          const statusCode = response.payload.status_code
          const platformName = payload.name
          if (response.payload?.data?.isMainStore) {
            if (fetchedUserAccountData?.is_platform_choice_done) {
              if (fetchedConnectedPlatformData[0]?.id) {
                dispatch(
                  setStepsData({
                    stepData: {stepOne: 'finished', stepTwo: 'finished', stepThree: 'active'},
                    visible: true,
                  })
                )
                setSelectedPlatformId(fetchedConnectedPlatformData[0]?.id + '')
              }
            } else {
              dispatch(
                setStepsData({
                  stepData: {stepOne: 'finished', stepTwo: 'active', stepThree: 'inactive'},
                  visible: true,
                })
              )
            }
          } else {
            setSelectedPlatformId(response.payload?.data?.id)
            dispatch(
              setStepsData({
                stepData: {stepOne: 'finished', stepTwo: 'finished', stepThree: 'active'},
                visible: true,
              })
            )
          }
          if (response?.payload?.status_code === 201) {
            successToast(`${selectedPlatform.display_name} Channel Connected Successfully`)
            setShowSettingModal(false)
          } else {
            errorToast(`Failed to connect platform ${platformName}. Status code: ${statusCode}`)
          }
        } else {
          errorToast('Failed to connect channel. Please try again.')
        }
      })
    }
  }

  return (
    <div className={`col-lg-3 col-md-4 col-sm-6 col-12 p-2`}>
      <div className='card shadow border-0 p-2 bg-hover-light-primary h-100'>
        {/* Card Image */}
        <img
          className='card-img-top mx-auto'
          src={data.logo_ref}
          alt={data.display_name}
          height='55px'
          style={{
            objectFit: 'contain',
            filter: 'drop-shadow(gray 1px 2px 4px)',
            maxWidth: '60px',
          }}
        />

        {/* Card Body */}
        <div className='card-body p-2'>
          {/* Card Title */}
          <div className='text-center fs-4 lh-1 card-title fw-bold mb-3 mt-1'>
            {data.display_name}
          </div>

          {/* Conditional Content */}
          {showConnectPlatformModal ? (
            <div>
              {/* Help Link */}
              <a
                target='_blank'
                href='https://commercium.tawk.help/'
                className='btn btn-sm btn-primary w-100 fw-bold'
                style={{fontSize: '12px'}}
              >
                Need Help?
              </a>

              {/* Modal Component */}
              <ConnectPlatformModal
                isVisible={showConnectPlatformModal}
                closeModal={handleCloseModal}
                data={selectedPlatform}
                onDataReceived={handleDataReceived}
              />
            </div>
          ) : (
            <div className='text-center'>
              {/* Connect Button */}
              <button
                className='btn btn-sm btn-info w-75 fw-bold'
                style={{fontSize: '12px'}}
                onClick={() => {
                  onClickConnect(data)
                  onClickConnectTop({platformId: data.id, isConnecting: true})
                }}
              >
                Connect
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Card
