import React, {FC, useState, useEffect} from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Modal from 'react-bootstrap/Modal'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {fetchPlatformsWithQueryAsync} from 'src/redux/actions/platformActions'
import MarketPlace from './MarketPlace'
import ECommercePlatforms from './ECommercePlatforms'
import CRMPlatforms from './CRMPlatforms'
import ShippingPlatforms from './ShippingPlatforms'
import AccountingPlatforms from './AccountingPlatforms'
import Settings from './Settings'
import NoPlatformAvailable from './NoPlatform'
import './Style/Onboard.css'
import ConnectedPlatformBanner from './ConnectedPlatformBanner'

const platformCategories = [
  {label: 'Available Marketplaces', type: 'MARKETPLACE'},
  {label: 'Available eCommerce', type: 'ECOMMERCE'},
  {label: 'Available ERPs and Inventory Management', type: 'CRM'},
  {label: 'Available Shipping Tools', type: 'SHIPMENT'},
  {label: 'Available B2B Tools', type: 'ACCOUNTING'},
  {label: 'Available POS', type: 'POS'},
]

const Onboard: FC = () => {
  const dispatch = useAppDispatch()
  const {fetchedConnectedPlatformData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const [show, setShow] = useState(false)
  const [activePlatformCategory, setActivePlatformCategory] = useState<string>('MARKETPLACE')
  const [platforms, setPlatforms] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const fetchPlatformsByType = async (type: string) => {
    setLoading(true)
    try {
      const response: any = await dispatch(fetchPlatformsWithQueryAsync({type})).unwrap()
      setPlatforms(response.data || [])
    } catch (error) {
      console.error('Failed to fetch platforms:', error)
      setPlatforms([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setShow(true)
    if (activePlatformCategory === 'SETTINGS' && fetchedConnectedPlatformData.length < 2) {
      setActivePlatformCategory('MARKETPLACE')
    } else if (activePlatformCategory !== 'SETTINGS') {
      fetchPlatformsByType(activePlatformCategory)
    }
  }, [activePlatformCategory, fetchedConnectedPlatformData.length])

  const renderContent = () => {
    if (loading) {
      return (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{minHeight: '80vh'}}
        >
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      )
    }

    if (platforms.length === 0) {
      return (
        <NoPlatformAvailable
          message='No Platforms Available'
          description='No data available for this platform type.'
        />
      )
    }

    switch (activePlatformCategory) {
      case 'MARKETPLACE':
        return <MarketPlace platforms={platforms} />
      case 'ECOMMERCE':
        return <ECommercePlatforms platforms={platforms} />
      case 'CRM':
        return <CRMPlatforms platforms={platforms} />
      case 'SHIPMENT':
        return <ShippingPlatforms platforms={platforms} />
      case 'ACCOUNTING':
        return <AccountingPlatforms platforms={platforms} />
      default:
        return null
    }
  }

  // Retained settings functionality
  const handleSettingsClick = () => {
    setActivePlatformCategory('SETTINGS')
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size='xl'
        centered
        backdrop='static'
        keyboard={false}
        animation={false}
        className='modal-static'
        dialogClassName='custom-modal-dialog'
      >
        <div className='bg-light py-3 px-4 border-bottom'>
          <div>
            <span className='text-primary fw-bold fs-5'>Connected Platforms</span>
            <ConnectedPlatformBanner onSettingsClick={handleSettingsClick} />
          </div>
        </div>
        <Modal.Body className='px-4' style={{maxHeight: '70vh', overflowY: 'auto'}}>
          {activePlatformCategory === 'SETTINGS' ? (
            <Settings />
          ) : (
            <Tabs
              id='platform-tabs'
              activeKey={activePlatformCategory}
              onSelect={(k) => setActivePlatformCategory(k || 'MARKETPLACE')}
              className='mb-3 custom-tabs'
            >
              {platformCategories.map((category) => (
                <Tab eventKey={category.type} title={category.label} key={category.type}>
                  {activePlatformCategory === category.type && renderContent()}
                </Tab>
              ))}
            </Tabs>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Onboard
