import React, {FC, useEffect} from 'react'
import {Button} from 'react-bootstrap'
import {useAppSelector, useAppDispatch} from 'src/redux/storeHooks'
import {fetchConnectedPlatformAsync} from 'src/redux/actions/platformActions'

const ConnectedPlatformBanner: FC<{onSettingsClick: () => void}> = ({onSettingsClick}) => {
  const dispatch = useAppDispatch()
  const {fetchedConnectedPlatformData, fetchedConnectedPlatformLoading} = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )

  useEffect(() => {
    // Dispatch action to fetch platforms only if there are no platforms fetched yet.
    if (!fetchedConnectedPlatformData?.length && !fetchedConnectedPlatformLoading) {
      console.log('Dispatching fetchConnectedPlatformAsync...')
      dispatch(fetchConnectedPlatformAsync())
    }
  }, [dispatch, fetchedConnectedPlatformData, fetchedConnectedPlatformLoading])

  const NoPlatformButton = (
    <Button
      className='px-4 py-2 fs-5'
      variant='primary'
      onClick={() => alert('Redirect to configuration page.')}
    >
      Platform setup
    </Button>
  )

  // Show the Configure button if there are no platforms connected
  if (!fetchedConnectedPlatformData?.length) {
    return (
      <div className='alert alert-dismissible bg-light-warning d-flex justify-content-between p-2 mt-2 align-items-center'>
        <div> {`{ Connect two or more platforms to configure }`}</div>
        {NoPlatformButton}
        <Button
          variant='primary'
          onClick={onSettingsClick} // Trigger the Settings tab in the parent component
          disabled={true} // Disabled when no platform or one platform is connected
          style={{
            width: '100px',
            height: '40px',
            marginTop: '10px', // Ensure it's visible even when there are no platforms
          }}
        >
          <i className='bi bi-gear me-2'></i> Settings
        </Button>
      </div>
    )
  }

  // Show the banner if there is exactly 1 platform connected and display the "Add Platform" button
  if (fetchedConnectedPlatformData.length === 1) {
    return (
      <div className='d-flex flex-column gap-3 mt-2'>
        <div className='d-flex gap-3 justify-content-between'>
          {fetchedConnectedPlatformData.map((platformToDisplay, index) => {
            return (
              <div
                key={index}
                className='alert alert-dismissible p-2 d-flex flex-column flex-sm-row justify-content-between align-items-center'
                style={{backgroundColor: '#1c127a'}}
              >
                <div className='d-flex align-items-center'>
                  <span className='svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0'>
                    <img
                      src={platformToDisplay?.platform_info?.logo_ref || '/default-logo.png'}
                      className='h-30px'
                      alt='Platform Logo'
                    />
                  </span>
                </div>
                <span className='svg-icon svg-icon-2x' style={{fontSize: '1.5rem'}}>
                  <i
                    className='bi bi-patch-check-fill'
                    style={{color: '#3ca33c', fontSize: '1.5rem'}}
                  ></i>
                </span>
              </div>
            )
          })}
          <div className='d-flex justify-content-center align-items-center gap-4'>
            {NoPlatformButton}
            <Button
              className='d-flex justify-content-center align-items-center'
              variant='primary'
              onClick={onSettingsClick} // Trigger the Settings tab in the parent component
              disabled={true} // Disabled when only one platform is connected
              style={{
                width: '100px',
                height: '40px',
              }}
            >
              <i className='bi bi-gear me-2'></i> Settings
            </Button>
          </div>
        </div>
      </div>
    )
  }

  // When there are multiple platforms connected, show the Settings button
  return (
    <div className='d-flex flex-column gap-3 mt-2'>
      <div className='d-flex gap-3'>
        {fetchedConnectedPlatformData.map((platformToDisplay, index) => {
          return (
            <div
              key={index}
              className='alert alert-dismissible p-2 d-flex flex-column flex-sm-row justify-content-between align-items-center'
              style={{backgroundColor: '#1c127a'}}
            >
              <div className='d-flex align-items-center'>
                <span className='svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0'>
                  <img
                    src={platformToDisplay?.platform_info?.logo_ref || '/default-logo.png'}
                    className='h-30px'
                    alt='Platform Logo'
                  />
                </span>
              </div>
              <span className='svg-icon svg-icon-2x' style={{fontSize: '1.5rem'}}>
                <i
                  className='bi bi-patch-check-fill'
                  style={{color: '#3ca33c', fontSize: '1.5rem'}}
                ></i>
              </span>
            </div>
          )
        })}
        {/* The Settings button will be enabled only when two or more platforms are connected */}
        <Button
          className='d-flex justify-content-center align-items-center ms-auto'
          variant='primary'
          onClick={onSettingsClick} // Trigger the Settings tab in the parent component
          disabled={fetchedConnectedPlatformData.length < 2} // Disabled if fewer than 2 platforms
          style={{
            width: '100px',
            height: '40px',
          }}
        >
          <i className='bi bi-gear me-2'></i> Settings
        </Button>
      </div>
    </div>
  )
}

export default ConnectedPlatformBanner
