import {FC, ReactNode} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {Button} from 'react-bootstrap' // Added import for Button
import { useAppSelector } from 'src/redux/storeHooks'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  onClick?: () => void // Added optional onClick prop
  badge?:boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  onClick, // Added onClick prop
  badge
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const { fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)

  console.log('----------------->>', window.location.pathname=='/pricing' && to.includes('/pricing') );
  

  return (
    <div className='menu-item'>
      {onClick ? ( // Render Button if onClick is provided
        <Button
          onClick={onClick}
          variant='link'
          className='menu-item ms-3 d-flex py-1 align-items-center '
          style={{textDecoration: 'none', color: (fetchedUserAccountData?.is_subscribed && fetchedUserAccountData?.isOnboardingProcessCompleted) ? 'white' : '#8E9BAA', fontWeight: 'bold'}} // Added styles
          disabled={(fetchedUserAccountData?.is_subscribed && fetchedUserAccountData?.isOnboardingProcessCompleted) ? false : true}
        >
          {icon && (
            <span className='menu-icon '>
              {/* Assuming you have an icon rendering mechanism */}
              <KTIcon iconName={icon} className='fs-2' />
            </span>
          )}
          {/* <span className='menu-title ms-2' style={{fontSize: '16px', paddingTop: '-10px'}}> */}
          <p className='menu-title ms-2 mb-0 ' style={{fontSize: '16px'}}>
            {' '}
            {title}{' '}
          </p>
          {/* </span> */}
        </Button>
      ) : (
        // Render Link for navigation if no onClick
        <Link
          className={clsx('menu-link without-sub', {active: isActive})}
          to={to}
          style={{textDecoration: 'none', pointerEvents: (fetchedUserAccountData?.is_subscribed && fetchedUserAccountData?.isOnboardingProcessCompleted)  ? 'auto' : 'none' }}
        >
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon '>
              {' '}
              <KTIcon iconName={icon} className='fs-2' />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className={( (fetchedUserAccountData?.is_subscribed && fetchedUserAccountData?.isOnboardingProcessCompleted) || (window.location.pathname=='/pricing' && to.includes('/pricing'))) ?  badge ?  'menu-title' : 'menu-title' : 'text-secondary' } style={{fontSize: '16px', fontWeight: 'bold'}}>
            {/* { badge ? badge : null} */}
            {title} { badge ? <sup><span className='badge badge-danger align-self-start ms-1 fs-bold' style={{fontSize:'8px'}} >Beta</span> </sup> : null}
          </span>
        </Link>
      )}
      {children}
    </div>
  )
}

export {SidebarMenuItem}
