import React from 'react'
import './Style/Onboard.css'

interface ExpandableSectionProps {
  title: string
  stateName: string
  values: any
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  children: React.ReactNode
}

const ExpandableSection: React.FC<ExpandableSectionProps> = ({
  title,
  stateName,
  values,
  handleChange,
  children,
}) => {
  return (
    <div className='mt-2 mb-2 border rounded'>
      <div className='row p-4'>
        <div className='col-12 col-sm-6'>
          <div className='form-check form-switch form-check-solid d-flex ps-0 align-items-center'>
            <label className='form-label me-10 me-sm-0 fs-5 mb-0' htmlFor={stateName}>
              {title}
            </label>
            <input
              className='form-check-input h-20px w-40px ms-5'
              type='checkbox'
              name={stateName}
              checked={values[stateName]}
              id={stateName}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      {values[stateName] && (
        <div className='row p-4 pt-0'>
          <div className='col-12'>{children}</div>
        </div>
      )}
    </div>
  )
}

export default ExpandableSection
