import React, { useState } from 'react';

const NumberInputWithArrows: React.FC = () => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    if (!isNaN(newValue)) {
      setValue(newValue);
    }
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12 col-sm-2">
          <div className="mb-3">
            <label htmlFor="quantity" className="form-label">
              Quantity
            </label>
            <input
              type="number"
              id="quantity"
              name="quantity"
              value={value}
              onChange={handleChange}
              min={0}
              step={1}
              className="form-control"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberInputWithArrows;
