import React, {FC, useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import DataTable, {TableColumn} from 'react-data-table-component'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {deleteInvitedUser, getUserInvitesListAsync, postInvitedUser} from 'src/redux/actions/userInviteAction' // Import the action creator for POST API call
import {USER_DATA} from 'src/app/_interfaces/auth.interface'
import InvitingUserModal from './InvitingUserModal'
import useAppToast from 'src/hooks/useAppToast'
import moment from 'moment'
import {UserActionsCell} from './UserActionCell'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'

const CommonColumn: FC<{rowData: string}> = ({rowData}) => {
  return (
    <>
      <p>{rowData}</p>
    </>
  )
}

type Invited_User = {
  id: number
  name: string
  email: string
  hasAccepted: boolean
  created_at: string
  updated_at: string
}

const UserInvites = () => {
  // const [currentUserData, setCurrentUserData] = useState<USER_DATA>()
  const {successToast, warningToast, errorToast} = useAppToast()
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 10,
  })
  const {userInviteLoading, userInvitePaginationData, userInviteList} = useAppSelector(
    (state) => state.userInvite
  )
  const {fetchedUserAccountData} = useAppSelector((state) => state.userAccountData)
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false)
  const [confirmDeleteInvitedUser, setConfirmDeleteInvitedUser] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)
  const [deletingUserData, setDeletingUserData] = useState<Invited_User|null>(null)
  const dispatch = useAppDispatch()

  // useEffect(() => {
  //   dispatch(fetchUserAccountDetailsAsync())
  //     .then((response) => {
  //       console.log('User account data:', response.payload)
  //       setCurrentUserData(response.payload) // Log the response data
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching user data:', error)
  //     })
  // }, [])

  useEffect(() => {
    if (fetchedUserAccountData?.id) {
      dispatch(
        getUserInvitesListAsync({
          userId: fetchedUserAccountData?.id,
          paramData: {...paginationData},
        })
      )
    }
  }, [fetchedUserAccountData, paginationData])

  const handleInviteUser = (values: {email: string; name: string}) => {
    const userData = {
      email: values.email,
      name: values.name,
      user_type: 'USER',
    }

    if (fetchedUserAccountData && fetchedUserAccountData?.id) {
      
      dispatch(postInvitedUser({userdata: userData, userId: fetchedUserAccountData?.id}))
        .then((response) => {
          console.log('handle--', response)
          if (response?.payload?.id) {
            setShowInviteModal(false) // Close the modal after successful invitation
            successToast('Invitation sent')
          } else {
            errorToast(response?.payload?.data?.error)
          }

          dispatch(
            getUserInvitesListAsync({
              userId: fetchedUserAccountData?.id,
              paramData: {...paginationData},
            })
          )
        })
        .catch((error) => {
          console.error('Error inviting user:', error)
          // Handle error or display notification to the user
        })
    }
  }

  const columns: TableColumn<Invited_User>[] = [
    {
      name: 'Name',
      cell: (row) => (row ? <CommonColumn rowData={row.name} /> : null),
    },
    {
      name: 'Email ID',
      cell: (row) => (row ? <CommonColumn rowData={row.email} /> : null),
    },
    {
      name: 'Invitation Accepted',
      cell: (row) => (row ? <CommonColumn rowData={row.hasAccepted ? 'Yes' : 'No'} /> : null),
    },
    {
      name: 'Created At',
      cell: (row) =>
        row.created_at ? moment.utc(row?.created_at).format('hh:mm A, DD-MMM-YYYY') : null,
    },
    // {
    //   name: 'Action',
    //   cell: (row) => <UserActionsCell onDeleteCustomer={() => {
    //     setDeletingUserData(row)
    //     setConfirmDeleteInvitedUser(true)
    //   }} />,
    //   center:true
    // },
  ]

  return (
    <>
      <div className='card shadow border-0'>
        <div className='card-header bg-white'>
          <div className='card-title'>User Invites</div>
          <div className='card-toolbar'>
            <button
              className='btn btn-primary btn-sm fw-bold min-w-100px text-center'
              onClick={() => {
                setShowInviteModal(true)
              }}
            >
              <p className='mb-0 fw-bold' ><i className="bi bi-person-fill-add fs-6 fw-bold"></i> Invite</p>
            </button>
          </div>
        </div>
        <div className='card-body py-0'>
          <DataTable
            fixedHeader={true}
            data={userInviteList}
            columns={columns}
            pagination
            paginationServer
            paginationTotalRows={userInvitePaginationData.count}
            progressPending={userInviteLoading}
            progressComponent={<Spinner animation='border' />}
            noDataComponent={<div>No users to show...</div>}
            onChangePage={(pageNumber, totalRows) => {
              setPaginationData((pre) => {
                return {...pre, page: pageNumber}
              })
            }}
            onChangeRowsPerPage={(currentPage) => {
              setPaginationData((pre) => {
                return {...pre, limit: currentPage}
              })
            }}
            customStyles={{
              table: {
                style: {
                  height: '60vh',
                },
              },
              headCells: {
                style: {fontWeight: 'bold', fontSize: '16px'},
              },
              // cells: {
              //   style: {
              //     padding: '10px',
              //     fontWeight:"bold",
              //     borderBottom: '1px solid gainsboro',
              //   },
              // },
              progress: {
                style: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%', // Set the height of the container to occupy the entire table height
                  marginTop: '100px', // Adjust the margin top to move it down
                },
              },
            }}
          />
        </div>
      </div>

      <InvitingUserModal
        show={showInviteModal}
        onClose={() => setShowInviteModal(false)}
        onInviteUser={handleInviteUser}
      />
      <CommonAlertModal
          key={'delete modal'}
          title={`Are you sure? Want to delete ${
            deletingUserData?.name ? deletingUserData.name : 'customer'
          }, do you want to continue?`}
          subtitle='Press "Confirm" for continue'
          successBtnTitle={deleting ? 'Deleting...' : 'Confirm Delete'}
          successDisabled={deleting}
          isVisible={confirmDeleteInvitedUser}
          onSuccess={() => {
            if (deletingUserData) {
              //delete functionality
              setDeleting(true)
              dispatch(deleteInvitedUser({user_id:deletingUserData.id}))
              .then((res)=>{
                  console.log('res', res)
                if(res.payload?.status_code==200){
                  successToast(res.payload?.massage)
                  setConfirmDeleteInvitedUser(false)
                  setDeletingUserData(null)
                  if (fetchedUserAccountData?.id) {
                    dispatch(
                      getUserInvitesListAsync({
                        userId: fetchedUserAccountData?.id,
                        paramData: {...paginationData},
                      })
                    )
                  }
                }else{
                  warningToast(res.payload?.data?.massage)
                }

                if(res.payload?.status==404){
                  warningToast(res?.payload?.statusText)
                }
                setDeleting(false)
                
              }).catch((err)=>{
                warningToast('Unable to delete user')
                setDeleting(false)
              })
            }
          }}
          onCancel={() => {
            setConfirmDeleteInvitedUser(false)
            setDeletingUserData(null)
          }}
          isTermsVisible={false}
        />
    </>
  )
}

export default UserInvites
