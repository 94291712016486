import {
  faArrowRightArrowLeft,
  faTimes,
  faPlus,
  faCross,
  faDeleteLeft,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {Fragment, useState} from 'react'
import {
  PLATFORM_FIELD,
  ShippingMethodMapping,
  platformfielddata_set,
} from 'src/interfaces/Platform.interface'

type WarehouseMappingValuesProps = {
  shippingMethodMappings: ShippingMethodMapping[]
  setShipment: (val: ShippingMethodMapping, itemIndex: number) => void
  addNewItem: () => void
  deleteMappingItem: (id: number | null, index: number | null) => void
  mainStoreShipmentData: platformfielddata_set[] | []
  selectedStoreShipmentData: platformfielddata_set[] | []
  mainStorePlatformField: null | PLATFORM_FIELD
  selectedPlatformOfPlatformField: null | PLATFORM_FIELD
}

const WarehouseMapping: React.FC<WarehouseMappingValuesProps> = ({
  shippingMethodMappings,
  setShipment,
  addNewItem,
  deleteMappingItem,
  mainStoreShipmentData,
  selectedStoreShipmentData,
  mainStorePlatformField,
  selectedPlatformOfPlatformField,
}) => {
  return (
    <>
      <div className='row'>
        <div className='mapping'>
          <div className=' p-1 '>
            <div>
              <label
                className='form-label required'
                // style={{fontSize: '1.3rem', color: 'black', fontWeight: 'bold'}}
              >
                Warehouse Mapping
              </label>
            </div>
            <div className='border-bottom my-2 border-2'></div>
            {shippingMethodMappings.length
              ? shippingMethodMappings.map((mapping: ShippingMethodMapping, index: number) => (
                  <div className=' mb-4 p-2 '>
                    <div key={index} className='row'>
                      {mainStorePlatformField?.input_type === 'SELECT' ? (
                        <div className='col-12 col-sm-5'>
                          <div className='d-flex flex-column justify-content-between h-100'>
                            <label
                              htmlFor=' primaryStoreShippingMethod'
                              className='me-2 form-check-label'
                            >
                              Primary Store Warehouses
                            </label>
                            <select
                              className='form-select form-select-lg mt-2'
                              id='primaryStoreShippingMethod'
                              value={mapping?.source_platform_data_id??''}
                              onChange={(ev) => {
                                let val: ShippingMethodMapping = {
                                  id: mapping.id ? mapping.id : null,
                                  source_platform_data_id: ev.target.value,
                                }

                                if (selectedPlatformOfPlatformField?.input_type === 'SELECT') {
                                  val.destination_platform_data_id =
                                    mapping.destination_platform_data_id
                                } else {
                                  val.destination_platform_data_str =
                                    mapping.destination_platform_data_str
                                }

                                setShipment(val, index)
                              }}
                            >
                              <option value=''>Open this select menu</option>
                              {mainStoreShipmentData?.length &&
                                mainStoreShipmentData.map((item: platformfielddata_set) => {
                                  return (
                                    <option value={item.id}>
                                      {item.api_name}
                                    </option>
                                  )
                                })}
                            </select>
                          </div>
                        </div>
                      ) : (
                        <div className='col-12 col-sm-5'>
                          <div className='d-flex flex-column justify-content-between h-100'>
                            <label
                              htmlFor=' primaryStoreShippingMethod'
                              className='me-2 form-check-label'
                            >
                              Primary Store Warehouses
                            </label>
                            <input
                              type='text'
                              placeholder='Shipment method'
                              className='form-control form-control-lg mt-2'
                              value={mapping.source_platform_data_str??''}
                              onChange={(ev) => {
                                let val: ShippingMethodMapping = {
                                  id: mapping.id ? mapping.id : null,
                                  source_platform_data_str: ev.target.value,
                                }
                                if (selectedPlatformOfPlatformField?.input_type === 'SELECT') {
                                  val.destination_platform_data_id =
                                    mapping.destination_platform_data_id
                                } else {
                                  val.destination_platform_data_str =
                                    mapping.destination_platform_data_str
                                }
                                setShipment(val, index)
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div className='col-12 col-sm-1 my-2 my-sm-0'>
                        <div className='d-flex justify-content-center align-items-center h-100'>
                          <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                        </div>
                      </div>
                      {selectedPlatformOfPlatformField?.input_type === 'SELECT' ? (
                        <div className='col-12 col-sm-5'>
                          <div className='d-flex flex-column justify-content-between h-100'>
                            <label
                              htmlFor=' secondaryStoreShippingMethod'
                              className='me-2 form-check-label'
                            >
                              Secondary Store Warehouses
                            </label>
                            <select
                              className='form-select form-select-lg mt-2'
                              id='secondaryStoreShippingMethod'
                              value={mapping?.destination_platform_data_id ?? ''}
                              onChange={(ev) => {
                                let val: ShippingMethodMapping = {
                                  id: mapping.id ? mapping.id : null,
                                  destination_platform_data_id: ev.target.value,
                                }
                                if (mainStorePlatformField?.input_type === 'SELECT') {
                                  val.source_platform_data_id = mapping.source_platform_data_id
                                } else {
                                  val.source_platform_data_str = mapping.source_platform_data_str
                                }
                                setShipment(val, index)
                              }}
                            >
                              <option value=''>Open this select menu</option>
                              {selectedStoreShipmentData?.length &&
                                selectedStoreShipmentData?.map((item: platformfielddata_set) => {
                                  return (
                                    <option value={item.id}>
                                      {item.api_name}
                                    </option>
                                  )
                                })}
                            </select>
                          </div>
                        </div>
                      ) : (
                        <div className='col-12 col-sm-5'>
                          <div className='d-flex flex-column justify-content-between h-100'>
                            <label
                              htmlFor=' secondaryStoreShippingMethod'
                              className='me-2 form-check-label'
                            >
                              Secondary Store Warehouse Method
                            </label>
                            <input
                              type='text'
                              placeholder='Shipment method'
                              className='form-control form-control-lg mt-2'
                              value={mapping.destination_platform_data_str??''}
                              onChange={(ev) => {
                                let val: ShippingMethodMapping = {
                                  id: mapping.id ? mapping.id : null,
                                  destination_platform_data_str: ev.target.value,
                                }
                                if (mainStorePlatformField?.input_type === 'SELECT') {
                                  val.source_platform_data_id = mapping.source_platform_data_id
                                } else {
                                  val.source_platform_data_str = mapping.source_platform_data_str
                                }
                                setShipment(val, index)
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {index !== 0 && shippingMethodMappings.length > 1 ? (
                        <div className='mt-2 col-12 col-sm-1'>
                          <div className='d-flex justify-content-end'>
                            <button
                              type='button'
                              onClick={() =>
                                deleteMappingItem(mapping.id ? mapping.id : null, index)
                              }
                              className='btn btn-sm'
                              style={{color: 'red'}}
                            >
                              <FontAwesomeIcon icon={faDeleteLeft} style={{fontSize: '20px'}} />
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))
              : null}
            <div className=' d-flex justify-content-end'>
              <button className='btn btn-primary btn-sm me-3 ' type='button' onClick={addNewItem}>
                <FontAwesomeIcon icon={faPlus} />
              </button>{' '}
              {/* Add button for adding new mapping */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default WarehouseMapping
