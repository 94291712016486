import { FC, ReactNode } from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const InfoTooltip: FC<{message:string|ReactNode}>   = ({message = ''}) => {
  return (
    <OverlayTrigger overlay={<Tooltip>{message}</Tooltip>}>
      <i className='bi bi-info-circle text-dark fs-5'></i>
    </OverlayTrigger>
  )
}

export default InfoTooltip
