import clsx from 'clsx'
import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import {Link, useParams} from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import {BillingPlan, UserSubscriptionPlan} from 'src/interfaces/Platform.interface'
import {useLayout} from 'src/marketConnect/layout/core'
import {fetchUserAccountDetailsAsync} from 'src/redux/actions/accountActions'
import {
  cancelSubscriptionAsync,
  getAllPlansAsync,
  getUserPlanAsync,
  requestFreePlanForWoocom,
  requestSubscriptionAsync,
  updateSubscriptionAsync,
} from 'src/redux/actions/pricingActions'
import {setStepsData} from 'src/redux/slices/tourPopupSlice'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import './PricingPage.css'
import BillingPlanDetail from '../BillingPlanDetail'

//After started Custom plan scenario
const UpdatedPricingPageV2: FC = () => {
  const [billingPeriod, setBillingPeriod] = useState<'month' | 'annual'>('month')
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null)
  const [listOfPlans, setListOfPlans] = useState<any[]>([])
  const [userSubscribedPlan, setUserSubscriptionPlan] = useState<UserSubscriptionPlan>()
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  let {platformId} = useParams()
  platformId = platformId ? platformId : `${mainStoreData?.id}`
  const dispatch = useAppDispatch()
  const {successToast, warningToast, errorToast} = useAppToast()
  const [isLoading, setIsLoading] = useState(false)
  const [isCancelSubscriptionConfirm, setIsCancelSubscriptionConfirm] = useState<boolean>(false)
  const [isCanceling, setIsCanceling] = useState<boolean>(false)
  const [isWixDowngrading, setIsWixDowngrading] = useState(false)
  const [isWixCancelling, setIsWixCancelling] = useState(false)
  const [confirmDowngradeToFree, setConfirmDowngradeToFree] = useState(false)
  const [isPlanClicked, setIsPlanClicked] = useState(false)
  const [downgradingPlanId, setDowngradingPlanId] = useState<number | null>(null)
  const [upgradingPlanId, setUpgradingPlanId] = useState<number | null>(null)
  const [trialingPlanId, setTrialingPlanId] = useState<number | null>(null)
  const {fetchedUserAccountData, fetchedUserAccountLoading} = useAppSelector((state) => state.userAccountData)
  const [showMsgForCustomPlanUser, setShowMsgForCustomPlanUser] = useState<boolean>(false)
  const [activePlanDetail, setActivePlanDetail] = useState<UserSubscriptionPlan | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  
  const handleBillingPeriodChange = (period) => {
    setBillingPeriod(period)
    // Reset selected plan when billing period changes
  }

  // Check user has any current active billing plan
  useEffect(() => {
    setLoading(true)
    dispatch(getUserPlanAsync())
      .then((response) => {
        if (response?.payload?.data?.billing_plan) {
          setActivePlanDetail(response.payload?.data)
        }
        if (response.payload?.status === 204) {
          setActivePlanDetail(null)
        }
        if (response.payload?.status === 500) {
          warningToast(response.payload?.data?.error)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    Promise.all([
      dispatch(getAllPlansAsync({user_id: fetchedUserAccountData?.id!})).then((response) => {
        if (Array.isArray(response.payload)) {
          setListOfPlans(response.payload)
        } else {
          console.error('Expected an array for listOfPlans:', response.payload)
        }
      }),
      dispatch(getUserPlanAsync()).then((response) => {
        console.log('list of  userplan', response.payload)
        if (response.payload?.data?.billing_plan) {
          setUserSubscriptionPlan({
            ...response.payload?.data,
            user_count: response.payload?.user_count,
          })

          if (response.payload?.data?.billing_plan?.billing_frequency == 'ANNUAL') {
            setBillingPeriod('annual')
          } else {
            setBillingPeriod('month')
          }
        }
        if (response.payload?.status === 204) {
          //will not get user billing plan
          setUserSubscriptionPlan(undefined)
          // warningToast(response.payload?.data?.error)
        }
        if (response.payload?.status === 500) {
          warningToast(response.payload?.data?.error)
        }
      }),
    ]).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const filteredPlans =
    billingPeriod === 'annual'
      ? listOfPlans.filter(
          (plan) => plan.billing_frequency === 'ANNUAL' || plan.billing_frequency === 'FREE_FOREVER'
        )
      : listOfPlans.filter(
          (plan) =>
            plan.billing_frequency === 'EVERY_30_DAYS' || plan.billing_frequency === 'FREE_FOREVER'
        )

  const calculatePrice = (plan: BillingPlan) => {
    if (plan.billing_frequency === 'ANNUAL') {
      const discount = 0.2 // 20% discount
      const discountedPrice = plan.price.value * (1 - discount)
      // return discountedPrice // Format to two decimal places
      return plan.price.value
    } else {
      return plan.price.value
    }
  }

  if (isLoading) return <Loader />

  const handleTabChange = (tab: 'month' | 'annual') => {
    setBillingPeriod(tab)
  }

  return (
    <>
      <div className='card'>
        {loading || fetchedUserAccountLoading ? (
          <Loader />
        ) : activePlanDetail ? (
          <BillingPlanDetail activePlanDetail={activePlanDetail} />
        ) : null}
        <div className='card-body py-3 px-2'>
          <div className='pricing-tab-container'>
            <div className='text-center'>
              <h1 className='fs-3 mb-2'>{activePlanDetail ? 'Upgrade' : 'Choose'} Your Plan</h1>
            </div>
            <div className='psp-tabs mt-4'>
              <button
                className={`psp-tab ${billingPeriod === 'month' ? 'psp-active' : ''}`}
                onClick={() => handleTabChange('month')}
              >
                Monthly
              </button>
              <button
                className={`psp-tab ${billingPeriod === 'annual' ? 'psp-active' : ''}`}
                onClick={() => handleTabChange('annual')}
              >
                Annually{' '}
                <span className='badge badge-warning text-dark px-2 ms-2 mb-0 py-1 mt-1 fs-9'>
                  Get 2 months free
                </span>
              </button>
              <div className={`tab-indicator ${billingPeriod}`} />
            </div>
            <div className='content'>
              {/* {billingPeriod === 'month' ? (
                        <div className="plan-details">
                        <h3>month Plan</h3>
                        <p>$10/month</p>
                        <p>Includes basic features.</p>
                        </div>
                    ) : (
                        <div className="plan-details">
                        <h3>annual Plan</h3>
                        <p>$100/year</p>
                        <p>Save 20% with an annual subscription.</p>
                        </div>
                    )} */}
              <div className='row row-cols-2 row-cols-lg-auto g-2 g-lg-2 g-10 gx-3 flex-wrap justify-content-center mt-1'>
                {filteredPlans?.length ? (
                  filteredPlans?.map((plan) => {
                    //entering in custom plan
                    if (
                      (plan?.price?.type == 'CUSTOM_PLAN' &&
                        plan?.user !== null &&
                        fetchedUserAccountData?.hasCustomPlan == false) ||
                      (plan?.price?.type == 'CUSTOM_PLAN' &&
                        plan?.user?.id !== fetchedUserAccountData?.id &&
                        fetchedUserAccountData?.hasCustomPlan == true)
                    ) {
                      //1 if user has not subscribed to the custom plan then show common custom plan
                      // is case  me user ka hasCustomPlan false aaega sath hi vo plan show hoga jiska user null rhega
                      //2 if user has subscribed to a custom plan then show only the matched user id custom plan
                      // is case me user ka hasCustomPlan true aaega and vhi custom plan show hoga jiska user id match krega logged in user k id se

                      return null
                    } else {
                      return (
                        <div
                          className='col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12 my-2'
                          style={{minHeight: '300px', minWidth: '230px'}}
                          key={plan.id}
                        >
                          <div className='d-flex h-100'>
                            <div
                              // style={planCardStyle(selectedPlanId === plan.plan_id)}
                              className={
                                userSubscribedPlan?.current_billing_plan === plan.id &&
                                userSubscribedPlan?.isActive
                                  ? 'w-100 d-flex flex-column rounded-3 bg-white bg-opacity-105 p-4 border border-2 border-primary shadow-lg bg-hover-light-primary'
                                  : 'w-100 d-flex flex-column  shadow rounded-3 bg-white bg-opacity-105 p-4 bg-hover-light-primary'
                              }
                              // onClick={() => handlePlanSelect(plan.plan_id)} // Handle plan selection on click
                            >
                              <div className='my-1 text-center w-100 '>
                                <h1 className=' fs-5  text-gray-900 fw-bolder p-2 text-truncate'>
                                  {plan.display_name}
                                </h1>
                              </div>
                              <div className='mb-2 text-center'>
                                <div className='text-center'>
                                  <span className=' text-primary fs-2'>
                                    { plan?.price?.value > 0
                                      ? plan?.price?.currency_iso === 'USD'
                                        ? ' $ '
                                        : ' ₹ '
                                      : ' '}
                                  </span>
                                  <span
                                    className='fw-bold text-primary lh-1'
                                    style={{  fontSize: '30px' }}
                                  >
                                    {plan?.price?.type === 'CUSTOM_PLAN' ? (
                                      fetchedUserAccountData?.hasCustomPlan ? (
                                        plan?.price?.value
                                      ) : (
                                        <p className='mb-2'>&nbsp;</p>
                                      )
                                    ) : plan?.price?.value > 0 ? (
                                      calculatePrice(plan)?.toFixed(2)
                                    ) : (
                                      'Free'
                                    )}
                                  </span>
                                  <span className='fs-8 fw-bold text-dark opacity-50'>
                                    {plan?.price?.value > 0
                                      ? billingPeriod === 'annual'
                                        ? '/Year'
                                        : '/Month'
                                      : null}
                                  </span>
                                </div>
                              </div>
                              <div className=' my-3'>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    {plan?.order_limit} Orders/Month
                                  </span>
                                  <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    {plan?.product_limit} SKUs
                                  </span>
                                  <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Unlimited Channel Integration
                                  </span>
                                  {plan?.isUnlimitedChannelIntegrationAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Inventory Sync
                                  </span>
                                  {plan?.isInventorySyncAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Product Listings
                                  </span>
                                  {plan?.isProductListingsAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Dedicated Account Manager
                                  </span>
                                  {plan?.isDedicatedAccountManagerAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Live Chat Support
                                  </span>
                                  {plan?.isLiveChatSupportAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Email Support
                                  </span>
                                  {plan?.isEmailSupportAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Near Realtime Sync
                                  </span>
                                  {plan?.isNearRealtimeSyncAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                              </div>

                              <div className='d-flex flex-wrap  justify-content-center  flex-column h-100 align-items-center'>
                                {(userSubscribedPlan?.cancelled_at &&
                                  userSubscribedPlan.current_billing_plan !== plan?.id) ||
                                userSubscribedPlan == undefined ||
                                userSubscribedPlan.cancelled_at === null ? (
                                  <>
                                    {userSubscribedPlan?.current_billing_plan === plan?.id &&
                                    userSubscribedPlan?.isActive ? (
                                      <button
                                        disabled
                                        className='bg-primary border-0 rounded pt-1 text-white fw-bold px-4'
                                        style={{paddingBottom: '5px'}}
                                      >
                                        Subscribed
                                      </button>
                                    ) : plan?.price?.type !== 'CUSTOM_PLAN' ? (
                                      plan?.price?.value >=
                                        Number(userSubscribedPlan?.billing_plan?.price?.value) ||
                                      userSubscribedPlan == undefined ||
                                      !userSubscribedPlan?.isActive ? (
                                        <button
                                          className='btn btn-sm btn-primary shadow-lg fw-bold fs-6 px-3'
                                          style={{paddingBottom: '8px', paddingTop: '6px'}}
                                          disabled={isPlanClicked}
                                          onClick={() => {
                                            if (
                                              userSubscribedPlan?.subscription_id === '' ||
                                              userSubscribedPlan?.subscription_id === '\n'
                                            ) {
                                              //if user is upgrading first time
                                              if (
                                                userSubscribedPlan?.activated_on == null &&
                                                userSubscribedPlan?.valid_till == null
                                              ) {
                                                setIsPlanClicked(true)
                                                setUpgradingPlanId(plan.id)
                                                dispatch(
                                                  requestSubscriptionAsync({
                                                    plan_id: plan.id,
                                                    platform_id: platformId ?? null,
                                                    // take_free_days_trial: plan.trialPeriodDays,
                                                  })
                                                )
                                                  .unwrap()
                                                  .then((response) => {
                                                    // Check if the response is successful
                                                    if (
                                                      response.status_code === 201 &&
                                                      response.data
                                                    ) {
                                                      // Automatically open the link in a new tab
                                                      // successToast(response?.massage)
                                                      window.open(response.data, '_self')
                                                      setTimeout(() => {
                                                        setIsPlanClicked(false)
                                                        setUpgradingPlanId(null)
                                                      }, 4000)
                                                    } else {
                                                      // Handle error or different status codes
                                                      console.error('Error response:', response)
                                                      warningToast(
                                                        'Something went wrong. Please try again later'
                                                      )
                                                      setIsPlanClicked(false)
                                                      setUpgradingPlanId(null)

                                                      // Optionally, show a user-friendly message or perform other actions
                                                    }
                                                  })
                                                  .catch((error) => {
                                                    // Handle any errors that occur during dispatch or response handling
                                                    console.error(
                                                      'Error during subscription request:',
                                                      error
                                                    )
                                                    if (
                                                      process.env['REACT_APP_ENV'] === 'staging'
                                                    ) {
                                                      warningToast(
                                                        'An error occurred ' +
                                                        error?.data?.massage
                                                      )
                                                    } else {
                                                      warningToast(
                                                        'An error occurred. Please try again later'
                                                      )
                                                    }
                                                    setIsPlanClicked(false)
                                                    setUpgradingPlanId(null)
                                                    // Optionally, show a user-friendly message or perform other actions
                                                  })
                                              } else {
                                                //request free plan
                                                if (plan?.billing_frequency === 'FREE_FOREVER') {
                                                  setSelectedPlanId(plan.plan_id)
                                                  setConfirmDowngradeToFree(true)
                                                } else {
                                                  // Dispatch requestSubscriptionAsync if no subscription_id
                                                  setIsPlanClicked(true)
                                                  setUpgradingPlanId(plan.id)
                                                  dispatch(
                                                    requestSubscriptionAsync({
                                                      plan_id: plan.id,
                                                      platform_id: platformId ?? null,
                                                      // take_free_days_trial: plan.trialPeriodDays,
                                                    })
                                                  )
                                                    .unwrap()
                                                    .then((response) => {
                                                      // Check if the response is successful
                                                      if (
                                                        response.status_code === 201 &&
                                                        response.data
                                                      ) {
                                                        // Automatically open the link in a new tab
                                                        // successToast(response?.massage)
                                                        window.open(response.data, '_self')
                                                        setTimeout(() => {
                                                          setIsPlanClicked(false)
                                                          setUpgradingPlanId(null)
                                                        }, 4000)
                                                      } else {
                                                        // Handle error or different status codes
                                                        console.error('Error response:', response)
                                                        warningToast(
                                                          'Something went wrong. Please try again later'
                                                        )
                                                        setIsPlanClicked(false)
                                                        setUpgradingPlanId(null)
                                                        // Optionally, show a user-friendly message or perform other actions
                                                      }
                                                    })
                                                    .catch((error) => {
                                                      // Handle any errors that occur during dispatch or response handling
                                                      console.error(
                                                        'Error during subscription request:',
                                                        error
                                                      )
                                                      if (
                                                        process.env['REACT_APP_ENV'] === 'staging'
                                                      ) {
                                                        warningToast(
                                                          'An error occurred ' +
                                                            error?.data?.massage
                                                        )
                                                      } else {
                                                        warningToast(
                                                          'An error occurred. Please try again later'
                                                        )
                                                      }
                                                      setIsPlanClicked(false)
                                                      setUpgradingPlanId(null)
                                                      // Optionally, show a user-friendly message or perform other actions
                                                    })
                                                }
                                              }
                                            } else {
                                              // Dispatch if subscription_id is present
                                              if (
                                                userSubscribedPlan == undefined &&
                                                plan?.billing_frequency === 'FREE_FOREVER'
                                              ) {
                                                setSelectedPlanId(plan.plan_id)
                                                setConfirmDowngradeToFree(true)
                                              } else {
                                                if (
                                                  userSubscribedPlan == undefined ||
                                                  (plan?.billing_frequency !== 'FREE_FOREVER' &&
                                                    !userSubscribedPlan?.subscription_id)
                                                ) {
                                                  setIsPlanClicked(true)
                                                  setUpgradingPlanId(plan.id)
                                                  dispatch(
                                                    requestSubscriptionAsync({
                                                      plan_id: plan.id,
                                                      platform_id: platformId ?? null,
                                                      // take_free_days_trial: plan.trialPeriodDays,
                                                    })
                                                  )
                                                    .unwrap()
                                                    .then((response) => {
                                                      // Check if the response is successful
                                                      if (
                                                        response.status_code === 201 &&
                                                        response.data
                                                      ) {
                                                        // Automatically open the link in a new tab
                                                        // successToast(response?.massage)
                                                        window.open(response.data, '_self')
                                                        setTimeout(() => {
                                                          setIsPlanClicked(false)
                                                          setUpgradingPlanId(null)
                                                        }, 4000)
                                                      } else {
                                                        // Handle error or different status codes
                                                        console.error('Error response:', response)
                                                        // Optionally, show a user-friendly message or perform other actions
                                                        warningToast(
                                                          'Something went wrong. Please try again later'
                                                        )
                                                        setIsPlanClicked(false)
                                                        setUpgradingPlanId(null)
                                                      }
                                                    })
                                                    .catch((error) => {
                                                      // Handle any errors that occur during dispatch or response handling
                                                      console.error(
                                                        'Error during subscription request:',
                                                        error
                                                      )
                                                      if (
                                                        process.env['REACT_APP_ENV'] === 'staging'
                                                      ) {
                                                        warningToast(
                                                          'An error occurred ' +
                                                            error?.data?.massage
                                                        )
                                                      } else {
                                                        warningToast(
                                                          'An error occurred. Please try again later'
                                                        )
                                                      }
                                                      setIsPlanClicked(false)
                                                      setUpgradingPlanId(null)
                                                      // Optionally, show a user-friendly message or perform other actions
                                                    })
                                                } else {
                                                  setIsPlanClicked(true)
                                                  setUpgradingPlanId(plan.id)
                                                  dispatch(
                                                    updateSubscriptionAsync({
                                                      plan_id: plan?.id,
                                                      platform_id: Number(platformId) ?? null,
                                                      subscription_id:
                                                        userSubscribedPlan?.subscription_id,
                                                    })
                                                  )
                                                    .unwrap()
                                                    .then((response) => {
                                                      // Check if the response is successful
                                                      if (
                                                        response?.status_code === 201 &&
                                                        response?.data
                                                      ) {
                                                        // successToast(response?.massage)
                                                        window.open(response.data, '_self')
                                                        setTimeout(() => {
                                                          setIsPlanClicked(false)
                                                          setUpgradingPlanId(null)
                                                        }, 4000)
                                                      } else {
                                                        // Handle error or different status codes

                                                        // errorToast(response?.error)
                                                        console.error('Error response:', response)
                                                        warningToast(
                                                          'Something went wrong. Please try again later'
                                                        )
                                                        setIsPlanClicked(false)
                                                        setUpgradingPlanId(null)
                                                        // Optionally, show a user-friendly message or perform other actions
                                                      }
                                                    })
                                                    .catch((error) => {
                                                      // Handle any errors that occur during dispatch or response handling
                                                      console.error(
                                                        'Error during subscription update:',
                                                        error
                                                      )
                                                      if (
                                                        process.env['REACT_APP_ENV'] === 'staging'
                                                      ) {
                                                        // warningToast('An error occurred ' + error?.data?.massage )
                                                        warningToast(error?.data?.massage)
                                                      } else {
                                                        // warningToast('An error occurred. Please try again later')
                                                        warningToast(error?.data?.massage)
                                                      }
                                                      setIsPlanClicked(false)
                                                      setUpgradingPlanId(null)
                                                      // Optionally, show a user-friendly message or perform other actions
                                                    })
                                                }
                                              }
                                            }
                                          }}
                                        >
                                          {upgradingPlanId === plan.id && isPlanClicked ? (
                                            <div className='min-w-100px'>
                                              <Spinner animation='border' size='sm' />
                                            </div>
                                          ) : userSubscribedPlan == undefined ||
                                            !userSubscribedPlan?.isActive ? (
                                            'Subscribe'
                                          ) : (
                                            'Upgrade'
                                          )}
                                        </button>
                                      ) : (
                                        <button
                                          className='btn btn-sm btn-primary shadow px-3 mt-2 fw-bold fs-7 pt-1'
                                          style={{paddingBottom: '5px'}}
                                          disabled={isPlanClicked}
                                          onClick={() => {
                                            if (
                                              ['WIX'].includes(fetchedUserAccountData?.source!)
                                            ) {
                                              if (userSubscribedPlan.cancelled_at !== null) {
                                                if (plan.billing_frequency !== 'FREE_FOREVER') {
                                                  setIsPlanClicked(true)
                                                  setDowngradingPlanId(plan.id)
                                                  dispatch(
                                                    requestSubscriptionAsync({
                                                      plan_id: plan.id,
                                                      platform_id: platformId ?? null,
                                                      // take_free_days_trial: plan.trialPeriodDays,
                                                    })
                                                  )
                                                    .unwrap()
                                                    .then((response) => {
                                                      // Check if the response is successful
                                                      if (
                                                        response.status_code === 201 &&
                                                        response.data
                                                      ) {
                                                        // Automatically open the link in a new tab
                                                        // successToast(response?.massage)
                                                        window.open(response.data, '_self')
                                                        setTimeout(() => {
                                                          setIsPlanClicked(false)
                                                          setDowngradingPlanId(null)
                                                        }, 4000)
                                                      } else {
                                                        // Handle error or different status codes
                                                        console.error('Error response:', response)
                                                        warningToast(
                                                          'Something went wrong. Please try again later'
                                                        )
                                                        setIsPlanClicked(false)
                                                        setDowngradingPlanId(null)
                                                        // Optionally, show a user-friendly message or perform other actions
                                                      }
                                                    })
                                                    .catch((error) => {
                                                      // Handle any errors that occur during dispatch or response handling
                                                      console.error(
                                                        'Error during subscription request:',
                                                        error
                                                      )
                                                      if (
                                                        process.env['REACT_APP_ENV'] === 'staging'
                                                      ) {
                                                        warningToast(
                                                          'An error occurred ' +
                                                            error?.data?.massage
                                                        )
                                                      } else {
                                                        warningToast(
                                                          'U An error occurred. Please try again later'
                                                        )
                                                      }
                                                      setIsPlanClicked(false)
                                                      setDowngradingPlanId(null)
                                                      // Optionally, show a user-friendly message or perform other actions
                                                    })
                                                } else {
                                                  setSelectedPlanId(plan.plan_id)
                                                  setConfirmDowngradeToFree(true)
                                                }
                                              } else {
                                                setIsWixDowngrading(true)
                                              }
                                            } else {
                                              if (
                                                userSubscribedPlan?.subscription_id === '' ||
                                                userSubscribedPlan?.subscription_id === '\n'
                                              ) {
                                                // Dispatch requestSubscriptionAsync if no subscription_id
                                                setIsPlanClicked(true)
                                                setDowngradingPlanId(plan.id)
                                                dispatch(
                                                  requestSubscriptionAsync({
                                                    plan_id: plan.id,
                                                    platform_id: platformId ?? null,
                                                    // take_free_days_trial: plan.trialPeriodDays,
                                                  })
                                                )
                                                  .unwrap()
                                                  .then((response) => {
                                                    // Check if the response is successful
                                                    if (
                                                      response.status_code === 201 &&
                                                      response.data
                                                    ) {
                                                      // Automatically open the link in a new tab
                                                      // successToast(response?.massage)
                                                      window.open(response.data, '_self')
                                                      setTimeout(() => {
                                                        setIsPlanClicked(false)
                                                        setDowngradingPlanId(null)
                                                      }, 4000)
                                                    } else {
                                                      // Handle error or different status codes
                                                      console.error('Error response:', response)
                                                      warningToast(
                                                        'Something went wrong. Please try again later'
                                                      )
                                                      setIsPlanClicked(false)
                                                      setDowngradingPlanId(null)
                                                      // Optionally, show a user-friendly message or perform other actions
                                                    }
                                                  })
                                                  .catch((error) => {
                                                    // Handle any errors that occur during dispatch or response handling
                                                    console.error(
                                                      'Error during subscription request:',
                                                      error
                                                    )
                                                    if (
                                                      process.env['REACT_APP_ENV'] === 'staging'
                                                    ) {
                                                      warningToast(
                                                        'An error occurred ' +
                                                        error?.data?.massage
                                                      )
                                                    } else {
                                                      warningToast(
                                                        'U An error occurred. Please try again later'
                                                      )
                                                    }
                                                    setIsPlanClicked(false)
                                                    setDowngradingPlanId(null)
                                                    // Optionally, show a user-friendly message or perform other actions
                                                  })
                                              } else {
                                                // Dispatch updateSubscriptionAsync if subscription_id is present
                                                if (plan?.billing_frequency === 'FREE_FOREVER') {
                                                  setSelectedPlanId(plan.plan_id)
                                                  setConfirmDowngradeToFree(true)
                                                } else {
                                                  setIsPlanClicked(true)
                                                  setDowngradingPlanId(plan.id)
                                                  dispatch(
                                                    updateSubscriptionAsync({
                                                      plan_id: plan?.id,
                                                      platform_id: Number(platformId) ?? null,
                                                      subscription_id:
                                                        userSubscribedPlan?.subscription_id,
                                                    })
                                                  )
                                                    .unwrap()
                                                    .then((response) => {
                                                      // Check if the response is successful
                                                      if (
                                                        response.status_code === 201 &&
                                                        response.data
                                                      ) {
                                                        // Automatically open the link in a new tab
                                                        window.open(response.data, '_self')
                                                        setTimeout(() => {
                                                          setIsPlanClicked(false)
                                                          setDowngradingPlanId(null)
                                                        }, 4000)
                                                      } else {
                                                        // Handle error or different status codes
                                                        console.error('Error response:', response)
                                                        warningToast(
                                                          'Something went wrong. Please try again later'
                                                        )
                                                        setIsPlanClicked(false)
                                                        setDowngradingPlanId(null)
                                                        // Optionally, show a user-friendly message or perform other actions
                                                      }
                                                    })
                                                    .catch((error) => {
                                                      // Handle any errors that occur during dispatch or response handling
                                                      console.error(
                                                        'Error during subscription update:',
                                                        error
                                                      )
                                                      if (
                                                        process.env['REACT_APP_ENV'] === 'staging'
                                                      ) {
                                                        warningToast(error?.data?.massage)
                                                      } else {
                                                        warningToast(error?.data?.massage)
                                                      }
                                                      setIsPlanClicked(false)
                                                      setDowngradingPlanId(null)
                                                      // Optionally, show a user-friendly message or perform other actions
                                                    })
                                                }
                                              }
                                            }
                                          }}
                                        >
                                          {downgradingPlanId === plan.id && isPlanClicked ? (
                                            <div className='min-w-100px'>
                                              <Spinner animation='border' size='sm' />
                                            </div>
                                          ) : (
                                            'Downgrade'
                                          )}
                                        </button>
                                      )
                                    ) : plan?.price?.value > 0 ? (  //this line checking for common custom plan
                                      plan?.price?.value >=
                                        Number(userSubscribedPlan?.billing_plan?.price?.value) ||
                                      userSubscribedPlan == undefined ||
                                      !userSubscribedPlan?.isActive ? (
                                        <button
                                          className='btn btn-sm btn-primary shadow-lg fw-bold fs-6 px-3'
                                          style={{paddingBottom: '8px', paddingTop: '6px'}}
                                          disabled={isPlanClicked}
                                          onClick={() => {
                                            if (
                                              userSubscribedPlan?.subscription_id === '' ||
                                              userSubscribedPlan?.subscription_id === '\n'
                                            ) {
                                              //if user is upgrading first time
                                              if (
                                                userSubscribedPlan?.activated_on == null &&
                                                userSubscribedPlan?.valid_till == null
                                              ) {
                                                setIsPlanClicked(true)
                                                setUpgradingPlanId(plan.id)
                                                dispatch(
                                                  requestSubscriptionAsync({
                                                    plan_id: plan.id,
                                                    platform_id: platformId ?? null,
                                                    // take_free_days_trial: plan.trialPeriodDays,
                                                  })
                                                )
                                                  .unwrap()
                                                  .then((response) => {
                                                    // Check if the response is successful
                                                    if (
                                                      response.status_code === 201 &&
                                                      response.data
                                                    ) {
                                                      // Automatically open the link in a new tab
                                                      // successToast(response?.massage)
                                                      window.open(response.data, '_self')
                                                      setTimeout(() => {
                                                        setIsPlanClicked(false)
                                                        setUpgradingPlanId(null)
                                                      }, 4000)
                                                    } else {
                                                      // Handle error or different status codes
                                                      console.error('Error response:', response)
                                                      warningToast(
                                                        'Something went wrong. Please try again later'
                                                      )
                                                      setIsPlanClicked(false)
                                                      setUpgradingPlanId(null)

                                                      // Optionally, show a user-friendly message or perform other actions
                                                    }
                                                  })
                                                  .catch((error) => {
                                                    // Handle any errors that occur during dispatch or response handling
                                                    console.error(
                                                      'Error during subscription request:',
                                                      error
                                                    )
                                                    if (
                                                      process.env['REACT_APP_ENV'] === 'staging'
                                                    ) {
                                                      warningToast(
                                                        'An error occurred ' +
                                                        error?.data?.massage
                                                      )
                                                    } else {
                                                      warningToast(
                                                        'An error occurred. Please try again later'
                                                      )
                                                    }
                                                    setIsPlanClicked(false)
                                                    setUpgradingPlanId(null)
                                                    // Optionally, show a user-friendly message or perform other actions
                                                  })
                                              } else {
                                                //request free plan
                                                if (plan?.billing_frequency === 'FREE_FOREVER') {
                                                  setSelectedPlanId(plan.plan_id)
                                                  setConfirmDowngradeToFree(true)
                                                } else {
                                                  // Dispatch requestSubscriptionAsync if no subscription_id
                                                  setIsPlanClicked(true)
                                                  setUpgradingPlanId(plan.id)
                                                  dispatch(
                                                    requestSubscriptionAsync({
                                                      plan_id: plan.id,
                                                      platform_id: platformId ?? null,
                                                      // take_free_days_trial: plan.trialPeriodDays,
                                                    })
                                                  )
                                                    .unwrap()
                                                    .then((response) => {
                                                      // Check if the response is successful
                                                      if (
                                                        response.status_code === 201 &&
                                                        response.data
                                                      ) {
                                                        // Automatically open the link in a new tab
                                                        // successToast(response?.massage)
                                                        window.open(response.data, '_self')
                                                        setTimeout(() => {
                                                          setIsPlanClicked(false)
                                                          setUpgradingPlanId(null)
                                                        }, 4000)
                                                      } else {
                                                        // Handle error or different status codes
                                                        console.error('Error response:', response)
                                                        warningToast(
                                                          'Something went wrong. Please try again later'
                                                        )
                                                        setIsPlanClicked(false)
                                                        setUpgradingPlanId(null)
                                                        // Optionally, show a user-friendly message or perform other actions
                                                      }
                                                    })
                                                    .catch((error) => {
                                                      // Handle any errors that occur during dispatch or response handling
                                                      console.error(
                                                        'Error during subscription request:',
                                                        error
                                                      )
                                                      if (
                                                        process.env['REACT_APP_ENV'] === 'staging'
                                                      ) {
                                                        warningToast(
                                                          'An error occurred ' +
                                                            error?.data?.massage
                                                        )
                                                      } else {
                                                        warningToast(
                                                          'An error occurred. Please try again later'
                                                        )
                                                      }
                                                      setIsPlanClicked(false)
                                                      setUpgradingPlanId(null)
                                                      // Optionally, show a user-friendly message or perform other actions
                                                    })
                                                }
                                              }
                                            } else {
                                              // Dispatch if subscription_id is present
                                              if (
                                                userSubscribedPlan == undefined &&
                                                plan?.billing_frequency === 'FREE_FOREVER'
                                              ) {
                                                setSelectedPlanId(plan.plan_id)
                                                setConfirmDowngradeToFree(true)
                                              } else {
                                                if (
                                                  userSubscribedPlan == undefined ||
                                                  (plan?.billing_frequency !== 'FREE_FOREVER' &&
                                                    !userSubscribedPlan?.subscription_id)
                                                ) {
                                                  setIsPlanClicked(true)
                                                  setUpgradingPlanId(plan.id)
                                                  dispatch(
                                                    requestSubscriptionAsync({
                                                      plan_id: plan.id,
                                                      platform_id: platformId ?? null,
                                                      // take_free_days_trial: plan.trialPeriodDays,
                                                    })
                                                  )
                                                    .unwrap()
                                                    .then((response) => {
                                                      // Check if the response is successful
                                                      if (
                                                        response.status_code === 201 &&
                                                        response.data
                                                      ) {
                                                        // Automatically open the link in a new tab
                                                        // successToast(response?.massage)
                                                        window.open(response.data, '_self')
                                                        setTimeout(() => {
                                                          setIsPlanClicked(false)
                                                          setUpgradingPlanId(null)
                                                        }, 4000)
                                                      } else {
                                                        // Handle error or different status codes
                                                        console.error('Error response:', response)
                                                        // Optionally, show a user-friendly message or perform other actions
                                                        warningToast(
                                                          'Something went wrong. Please try again later'
                                                        )
                                                        setIsPlanClicked(false)
                                                        setUpgradingPlanId(null)
                                                      }
                                                    })
                                                    .catch((error) => {
                                                      // Handle any errors that occur during dispatch or response handling
                                                      console.error(
                                                        'Error during subscription request:',
                                                        error
                                                      )
                                                      if (
                                                        process.env['REACT_APP_ENV'] === 'staging'
                                                      ) {
                                                        warningToast(
                                                          'An error occurred ' +
                                                            error?.data?.massage
                                                        )
                                                      } else {
                                                        warningToast(
                                                          'An error occurred. Please try again later'
                                                        )
                                                      }
                                                      setIsPlanClicked(false)
                                                      setUpgradingPlanId(null)
                                                      // Optionally, show a user-friendly message or perform other actions
                                                    })
                                                } else {
                                                  if (plan?.description === "BILLED_MANUALLY") {
                                                    setShowMsgForCustomPlanUser(true)
                                                  }else{
                                                    setIsPlanClicked(true)
                                                    setUpgradingPlanId(plan.id)
                                                    dispatch(
                                                      updateSubscriptionAsync({
                                                        plan_id: plan?.id,
                                                        platform_id: Number(platformId) ?? null,
                                                        subscription_id:
                                                          userSubscribedPlan?.subscription_id,
                                                      })
                                                    )
                                                      .unwrap()
                                                      .then((response) => {
                                                        // Check if the response is successful
                                                        if (
                                                          response?.status_code === 201 &&
                                                          response?.data
                                                        ) {
                                                          // successToast(response?.massage)
                                                          window.open(response.data, '_self')
                                                          setTimeout(() => {
                                                            setIsPlanClicked(false)
                                                            setUpgradingPlanId(null)
                                                          }, 4000)
                                                        } else {
                                                          // Handle error or different status codes

                                                          // errorToast(response?.error)
                                                          console.error('Error response:', response)
                                                          warningToast(
                                                            'Something went wrong. Please try again later'
                                                          )
                                                          setIsPlanClicked(false)
                                                          setUpgradingPlanId(null)
                                                          // Optionally, show a user-friendly message or perform other actions
                                                        }
                                                      })
                                                      .catch((error) => {
                                                        // Handle any errors that occur during dispatch or response handling
                                                        console.error(
                                                          'Error during subscription update:',
                                                          error
                                                        )
                                                        if (
                                                          process.env['REACT_APP_ENV'] === 'staging'
                                                        ) {
                                                          // warningToast('An error occurred ' + error?.data?.massage )
                                                          warningToast(error?.data?.massage)
                                                        } else {
                                                          // warningToast('An error occurred. Please try again later')
                                                          warningToast(error?.data?.massage)
                                                        }
                                                        setIsPlanClicked(false)
                                                        setUpgradingPlanId(null)
                                                        // Optionally, show a user-friendly message or perform other actions
                                                      })
                                                  }}
                                              }
                                            }
                                          }}
                                        >
                                          {upgradingPlanId === plan.id && isPlanClicked ? (
                                            <div className='min-w-100px'>
                                              <Spinner animation='border' size='sm' />
                                            </div>
                                          ) : userSubscribedPlan == undefined ||
                                            !userSubscribedPlan?.isActive ? (
                                            'Subscribe'
                                          ) : (
                                            'Upgrade'
                                          )}
                                        </button>
                                      ) : (
                                        <button
                                          className='btn btn-sm btn-primary shadow px-3 mt-2 fw-bold fs-7 pt-1'
                                          style={{paddingBottom: '5px'}}
                                          disabled={isPlanClicked}
                                          onClick={() => {
                                            if (plan?.description === 'BILLED_MANUALLY') {
                                              setShowMsgForCustomPlanUser(true)
                                            } else {
                                              if (
                                                ['WIX'].includes(fetchedUserAccountData?.source!)
                                              ) {
                                                if (userSubscribedPlan.cancelled_at !== null) {
                                                  if (plan.billing_frequency !== 'FREE_FOREVER') {
                                                    setIsPlanClicked(true)
                                                    setDowngradingPlanId(plan.id)
                                                    dispatch(
                                                      requestSubscriptionAsync({
                                                        plan_id: plan.id,
                                                        platform_id: platformId ?? null,
                                                        // take_free_days_trial: plan.trialPeriodDays,
                                                      })
                                                    )
                                                      .unwrap()
                                                      .then((response) => {
                                                        // Check if the response is successful
                                                        if (
                                                          response.status_code === 201 &&
                                                          response.data
                                                        ) {
                                                          // Automatically open the link in a new tab
                                                          // successToast(response?.massage)
                                                          window.open(response.data, '_self')
                                                          setTimeout(() => {
                                                            setIsPlanClicked(false)
                                                            setDowngradingPlanId(null)
                                                          }, 4000)
                                                        } else {
                                                          // Handle error or different status codes
                                                          console.error('Error response:', response)
                                                          warningToast(
                                                            'Something went wrong. Please try again later'
                                                          )
                                                          setIsPlanClicked(false)
                                                          setDowngradingPlanId(null)
                                                          // Optionally, show a user-friendly message or perform other actions
                                                        }
                                                      })
                                                      .catch((error) => {
                                                        // Handle any errors that occur during dispatch or response handling
                                                        console.error(
                                                          'Error during subscription request:',
                                                          error
                                                        )
                                                        if (
                                                          process.env['REACT_APP_ENV'] === 'staging'
                                                        ) {
                                                          warningToast(
                                                            'An error occurred ' +
                                                              error?.data?.massage
                                                          )
                                                        } else {
                                                          warningToast(
                                                            'U An error occurred. Please try again later'
                                                          )
                                                        }
                                                        setIsPlanClicked(false)
                                                        setDowngradingPlanId(null)
                                                        // Optionally, show a user-friendly message or perform other actions
                                                      })
                                                  } else {
                                                    setSelectedPlanId(plan.plan_id)
                                                    setConfirmDowngradeToFree(true)
                                                  }
                                                } else {
                                                  setIsWixDowngrading(true)
                                                }
                                              } else {
                                                if (
                                                  userSubscribedPlan?.subscription_id === '' ||
                                                  userSubscribedPlan?.subscription_id === '\n'
                                                ) {
                                                  // Dispatch requestSubscriptionAsync if no subscription_id
                                                  setIsPlanClicked(true)
                                                  setDowngradingPlanId(plan.id)
                                                  dispatch(
                                                    requestSubscriptionAsync({
                                                      plan_id: plan.id,
                                                      platform_id: platformId ?? null,
                                                      // take_free_days_trial: plan.trialPeriodDays,
                                                    })
                                                  )
                                                    .unwrap()
                                                    .then((response) => {
                                                      // Check if the response is successful
                                                      if (
                                                        response.status_code === 201 &&
                                                        response.data
                                                      ) {
                                                        // Automatically open the link in a new tab
                                                        // successToast(response?.massage)
                                                        window.open(response.data, '_self')
                                                        setTimeout(() => {
                                                          setIsPlanClicked(false)
                                                          setDowngradingPlanId(null)
                                                        }, 4000)
                                                      } else {
                                                        // Handle error or different status codes
                                                        console.error('Error response:', response)
                                                        warningToast(
                                                          'Something went wrong. Please try again later'
                                                        )
                                                        setIsPlanClicked(false)
                                                        setDowngradingPlanId(null)
                                                        // Optionally, show a user-friendly message or perform other actions
                                                      }
                                                    })
                                                    .catch((error) => {
                                                      // Handle any errors that occur during dispatch or response handling
                                                      console.error(
                                                        'Error during subscription request:',
                                                        error
                                                      )
                                                      if (
                                                        process.env['REACT_APP_ENV'] === 'staging'
                                                      ) {
                                                        warningToast(
                                                          'An error occurred ' +
                                                            error?.data?.massage
                                                        )
                                                      } else {
                                                        warningToast(
                                                          'U An error occurred. Please try again later'
                                                        )
                                                      }
                                                      setIsPlanClicked(false)
                                                      setDowngradingPlanId(null)
                                                      // Optionally, show a user-friendly message or perform other actions
                                                    })
                                                } else {
                                                  // Dispatch updateSubscriptionAsync if subscription_id is present
                                                  if (plan?.billing_frequency === 'FREE_FOREVER') {
                                                    setSelectedPlanId(plan.plan_id)
                                                    setConfirmDowngradeToFree(true)
                                                  } else {
                                                    setIsPlanClicked(true)
                                                    setDowngradingPlanId(plan.id)
                                                    dispatch(
                                                      updateSubscriptionAsync({
                                                        plan_id: plan?.id,
                                                        platform_id: Number(platformId) ?? null,
                                                        subscription_id:
                                                          userSubscribedPlan?.subscription_id,
                                                      })
                                                    )
                                                      .unwrap()
                                                      .then((response) => {
                                                        // Check if the response is successful
                                                        if (
                                                          response.status_code === 201 &&
                                                          response.data
                                                        ) {
                                                          // Automatically open the link in a new tab
                                                          window.open(response.data, '_self')
                                                          setTimeout(() => {
                                                            setIsPlanClicked(false)
                                                            setDowngradingPlanId(null)
                                                          }, 4000)
                                                        } else {
                                                          // Handle error or different status codes
                                                          console.error('Error response:', response)
                                                          warningToast(
                                                            'Something went wrong. Please try again later'
                                                          )
                                                          setIsPlanClicked(false)
                                                          setDowngradingPlanId(null)
                                                          // Optionally, show a user-friendly message or perform other actions
                                                        }
                                                      })
                                                      .catch((error) => {
                                                        // Handle any errors that occur during dispatch or response handling
                                                        console.error(
                                                          'Error during subscription update:',
                                                          error
                                                        )
                                                        if (
                                                          process.env['REACT_APP_ENV'] === 'staging'
                                                        ) {
                                                          warningToast(error?.data?.massage)
                                                        } else {
                                                          warningToast(error?.data?.massage)
                                                        }
                                                        setIsPlanClicked(false)
                                                        setDowngradingPlanId(null)
                                                        // Optionally, show a user-friendly message or perform other actions
                                                      })
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                        >
                                          {downgradingPlanId === plan.id && isPlanClicked ? (
                                            <div className='min-w-100px'>
                                              <Spinner animation='border' size='sm' />
                                            </div>
                                          ) : (
                                            'Downgrade'
                                          )}
                                        </button>
                                      )
                                    ) : (
                                      <a
                                        href='mailto:help@mycommercium.com'
                                        className='btn btn-sm btn-primary fw-bold fs-6'
                                      >
                                        Contact Us
                                      </a>
                                    )}
                                  </>
                                ) : null}

                                {userSubscribedPlan?.current_billing_plan == plan.id &&
                                userSubscribedPlan?.cancelled_at !== null &&
                                plan?.billing_frequency !== 'FREE_FOREVER' ? (
                                  <>
                                    <button
                                      className='btn btn-sm btn-danger shadow m-2 fw-bold fs-7 pt-1 w-150px'
                                      style={{paddingBottom: '4px'}}
                                      disabled
                                    >
                                      Cancelled
                                    </button>
                                    <p className='fs-8 fw-bold'>
                                      Valid Till{' '}
                                      {userSubscribedPlan?.valid_till
                                        ? moment
                                            .utc(userSubscribedPlan.valid_till)
                                            .format('hh:mm A, DD-MM-YYYY')
                                        : ''}
                                    </p>
                                  </>
                                ) : null}

                                {userSubscribedPlan?.current_billing_plan === plan?.id &&
                                userSubscribedPlan?.isActive && userSubscribedPlan.billing_plan.description !=="BILLED_MANUALLY" &&
                                userSubscribedPlan.cancelled_at === null
                                  ? plan.trialPeriodDays > 0 && (
                                      <>
                                        <button
                                          className='btn btn-sm btn-danger shadow m-2 fw-bold fs-7 pt-1'
                                          disabled={isPlanClicked}
                                          style={{paddingBottom: '4px'}}
                                          onClick={() => {
                                            if (['WIX'].includes(fetchedUserAccountData?.source!)) {
                                              setIsWixCancelling(true)
                                            } else {
                                              setIsCancelSubscriptionConfirm(true)
                                            }
                                          }}
                                        >
                                          Cancel Subscription
                                        </button>
                                      </>
                                    )
                                  : plan.trialPeriodDays > 0 &&
                                    (userSubscribedPlan?.billing_plan.billing_frequency !==
                                      'FREE_FOREVER' ||
                                      (plan.billing_frequency !== 'FREE_FOREVER' &&
                                        userSubscribedPlan?.activated_on == null)) &&
                                    !['COMMERCIUM'].includes(fetchedUserAccountData?.source!) &&
                                    null}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })
                ) : (
                  <div className='card shadow'>
                    <div className='card-body'>
                      <h3>No Plans Found</h3>
                    </div>
                  </div>
                )}
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <Link
                  className={clsx(
                    'btn btn-sm btn-primary align-self-center fs-5  mt-4 mb-3 fw-bold',
                    !fetchedUserAccountData?.is_subscribed ? 'w-200px' : ''
                  )}
                  style={{pointerEvents: fetchedUserAccountData?.is_subscribed ? 'auto' : 'none'}}
                  to={'/listing-channels'}
                >
                  {fetchedUserAccountData?.isOnboardingProcessCompleted
                    ? 'Connect a new Channel or Change settings'
                    : 'Get Started'}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonAlertModal
        title={
          ['WIX'].includes(fetchedUserAccountData?.source!)
            ? 'You have to cancel your current plan from Wix admin portal.'
            : `Are you sure to cancel your subscription? It will remain active until your current plan expires. After that your plan will be downgraded to a Free plan.`
        }
        subtitle=''
        isTermsVisible={false}
        successDisabled={isCanceling}
        successBtnTitle={isCanceling ? 'Updating...' : 'Yes Confirm'}
        isVisible={isCancelSubscriptionConfirm}
        onCancel={() => {
          setIsCancelSubscriptionConfirm(false)
        }}
        onSuccess={() => {
          if (!['WIX'].includes(fetchedUserAccountData?.source!)) {
            setIsCanceling(true)
            dispatch(
              cancelSubscriptionAsync({
                subscription_id: userSubscribedPlan?.subscription_id,
                platform_id: platformId ?? null,
              })
            ).then((response) => {
              if (response?.payload?.status_code === 201 && response?.payload?.data) {
                successToast(response?.payload?.message)
                setIsCanceling(false)
                setIsCancelSubscriptionConfirm(false)
                dispatch(getUserPlanAsync()).then((response) => {
                  if (response.payload?.data?.billing_plan) {
                    setUserSubscriptionPlan({
                      ...response.payload?.data,
                      user_count: response.payload?.user_count,
                    })
                  }
                  if (response.payload?.status === 204) {
                    //will not get user billing plan
                    setUserSubscriptionPlan(undefined)
                    // warningToast(response.payload?.data?.error)
                  }
                  if (response.payload?.status === 500) {
                    warningToast(response.payload?.data?.error)
                  }
                })
              } else {
                errorToast(response?.payload?.message)
                setIsCanceling(false)
                dispatch(getUserPlanAsync()).then((response) => {
                  if (response.payload?.data?.billing_plan) {
                    setUserSubscriptionPlan({
                      ...response.payload?.data,
                      user_count: response.payload?.user_count,
                    })
                  }
                  if (response.payload?.status === 204) {
                    //will not get user billing plan
                    setUserSubscriptionPlan(undefined)
                    // warningToast(response.payload?.data?.error)
                  }
                  if (response.payload?.status === 500) {
                    warningToast(response.payload?.data?.error)
                  }
                })
              }
            })
          } else {
            setIsCancelSubscriptionConfirm(false)
          }
        }}
      />

      {/* downgrade to free plan confirmation popup */}
      <CommonAlertModal
        title={
          userSubscribedPlan == undefined
            ? `Are you sure you want to proceed with the free plan?`
            : `Are you sure you want to downgrade to the Free plan?`
        }
        subtitle=''
        isTermsVisible={false}
        successDisabled={isCanceling}
        successBtnTitle={isCanceling ? 'Updating...' : 'Yes Confirm'}
        isVisible={confirmDowngradeToFree}
        onCancel={() => {
          setConfirmDowngradeToFree(false)
          setSelectedPlanId(null)
        }}
        onSuccess={() => {
          setIsCanceling(true)
          dispatch(requestFreePlanForWoocom({plan_name: selectedPlanId}))
            .unwrap()
            .then((response) => {
              if (response.status_code === 200) {
                // Automatically open the link in a new tab
                successToast(response?.message)
                setIsCanceling(false)
                setIsLoading(true)
                Promise.all([
                  dispatch(fetchUserAccountDetailsAsync()),
                  dispatch(getAllPlansAsync({user_id: fetchedUserAccountData?.id!})).then(
                    (response) => {
                      if (Array.isArray(response.payload)) {
                        setListOfPlans(response.payload)
                      } else {
                        console.error('Expected an array for listOfPlans:', response.payload)
                      }
                    }
                  ),
                  dispatch(getUserPlanAsync()).then((response) => {
                    console.log('list of  userplan', response)
                    if (response.payload?.data?.billing_plan) {
                      setUserSubscriptionPlan({
                        ...response.payload?.data,
                        user_count: response.payload?.user_count,
                      })
                    }
                    if (response.payload?.status === 204) {
                      //will not get user billing plan
                      setUserSubscriptionPlan(undefined)
                      // warningToast(response.payload?.data?.error)
                    }
                    if (response.payload?.status === 500) {
                      warningToast(response.payload?.data?.error)
                    }
                  }),
                ]).finally(() => {
                  setIsLoading(false)
                  dispatch(
                    setStepsData({
                      stepData: {stepOne: 'active', stepTwo: 'inactive', stepThree: 'inactive'},
                      visible: true,
                    })
                  )
                })
              } else {
                // Handle error or different status codes
                console.log('Error response:', response)
                setIsLoading(false)
                setIsCanceling(false)
                // Optionally, show a user-friendly message or perform other actions
              }
              setConfirmDowngradeToFree(false)
            })
            .catch((error) => {
              // Handle any errors that occur during dispatch or response handling
              setConfirmDowngradeToFree(false)
              setIsCanceling(false)
              setIsPlanClicked(false)
              console.error('Error during subscription request:', error)
            })
        }}
      />

      <CommonAlertModal
        key={'WIX_USER_ALERT'}
        title={
          isWixDowngrading
            ? 'You need to cancel your current plan first through the Wix admin portal, and then you can choose your desired plan. Wix does not allow customers to downgrade plans through third-party sources.'
            : isWixCancelling
            ? 'Wix does not permit customers to manage their plans through third-party platforms. To cancel your plan, please use the Wix Admin Portal.'
            : ''
        }
        subtitle=''
        isTermsVisible={false}
        successBtnTitle={'Ok'}
        showCloseButton={false}
        isVisible={isWixDowngrading || isWixCancelling}
        onCancel={() => {}}
        onSuccess={() => {
          setIsWixDowngrading(false)
          setIsWixCancelling(false)
        }}
      />

      <CommonAlertModal
        key={'CUSTOM_PLAN_USER_ALERT'}
        title={
          <p className='fs-5'>
            Please contact administrator to Subscribe/Upgrade/Downgrade your plan. To connect with
            administrator <a href='mailto:help@mycommercium.com'>Click Here</a>{' '}
          </p>
        }
        subtitle=''
        isTermsVisible={false}
        successBtnTitle={'Ok'}
        showCloseButton={false}
        isVisible={showMsgForCustomPlanUser}
        onCancel={() => {}}
        onSuccess={() => {
          setShowMsgForCustomPlanUser(false)
        }}
      />
    </>
  )
}

export default UpdatedPricingPageV2
