import { fetchTemuCountryAsync } from "../actions/accountActions";
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

type fetchTemuCountryPayoad = {
    temuCountryData:any,
    loading: boolean,
}
const initialState: fetchTemuCountryPayoad = {
    temuCountryData: [],
    loading: false,
}

const fetchTemuCountrySlice = createSlice({
  name: 'fetchTemuCountryDataSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(fetchTemuCountryAsync.pending), (state, actions) => {
      state.loading = true
    })
    builder.addMatcher(isAnyOf(fetchTemuCountryAsync.fulfilled), (state, action) => {
      state.temuCountryData = action?.payload
      console.log("state.fetchedTemuCountryData:",state.temuCountryData)
      state.loading = false
    })
    builder.addMatcher(isAnyOf(fetchTemuCountryAsync.rejected), (state) => {
      state.loading = false
    })
  },
  reducers: {
    getTemuCountryData: (state, action: {payload: fetchTemuCountryPayoad; type: string}) => {
      state.loading = action.payload?.loading
      state.temuCountryData = action?.payload.temuCountryData
    },
  },
});

export const {getTemuCountryData} = fetchTemuCountrySlice.actions
export default fetchTemuCountrySlice.reducer;