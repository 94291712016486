import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const fetchUserAccountDetailsAsync = createAsyncThunk(
  'user/fetchUserAccountDetailsAsync',
  async function (payload, toolkit) {
    return await AxiosClient('GET', '/api/authenticated-user', [], toolkit, [])
  }
)

export const fetchTemuCountryAsync = createAsyncThunk(
  'user/fetchTemuCountry',
  async function (payload, toolkit) {
    return await AxiosClient('GET', '/api/temu-country', [], toolkit, [])
  }
)

export const fetchSkipSetupAsync = createAsyncThunk(
  'user/fetchSkipSetupAsync',
  async function (payload, toolkit) {
    return await AxiosClient('GET', '/user/skip-setup', [], toolkit, [])
  }
)
