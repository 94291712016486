import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthenticatedAxiosClient } from "../AxiosClientForFile";
export const uploadAsync = createAsyncThunk(
  "platform/uploadCsv",
  async function(payload: { id: string; csvFile, type:string}, toolkit) {
  
      console.log("payload:",payload.csvFile)
     

      // const buffer=  Buffer.from(payload.csvFile.name,"utf-8")
      // console.log("buffer,",buffer)
      const formData = new FormData();
      formData.append('file', payload?.csvFile); // Append file with its name
      formData.append('platform_id', payload.id);
      formData.append('feature', payload.type); // Append type

 

      // Make the POST request using AuthenticatedAxiosClient
      return await AuthenticatedAxiosClient('POST', '/platform-bulk-process', formData, toolkit,[]);

      // Return the response data
    
   
  }
);

// export const getCsvAsync = createAsyncThunk(
//   "platform/getCsvAsync",
//   async function(payload: { status:string }) {
    
 

//       // Make the POST request using AuthenticatedAxiosClient
//       return await AuthenticatedAxiosClient('GET', '/platform-bulk-process?process_status=' + payload.status,{}, {}, []);

//       // Return the response data
    
//     } 
  
// );


