import React, {FC, useState} from 'react'
import ExpandableSection from './ExpandableSection'
import Select from 'react-select'
import SwitchInput from './toggle-settings/SwitchInput'
import ShortCodeInput from './toggle-settings/InputBoxes'
import ImportShipmentMapping from './toggle-settings/ImportShipmentToggle'
import NumberInputWithArrowsKeyboard from './toggle-settings/NumberInput'
import DynamicSelectSetting from './toggle-settings/DynamicSelectBox'
import InfoTooltip from './InfoTooltip'
import {SETTINGS_OF_PLATFORM} from 'src/interfaces/Platform.interface'

type ExpandedSectionsProps = {
  expandedSections: Record<string, boolean>
  setExpandedSections: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  formValues: any
  setFormValues: React.Dispatch<React.SetStateAction<any>>
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ExpandedSections: FC<ExpandedSectionsProps> = ({
  expandedSections,
  formValues,
  setFormValues,
  handleChange,
}) => {
  const selectOptions = [
    {label: 'Shopify', value: 'shopify'},
    {label: 'Amazon', value: 'amazon'},
    {label: 'Flipkart', value: 'flipkart'},
    {label: 'WooCommerce', value: 'woocommerce'},
  ]
  const dummySourceOptions = [
    {value: '1', label: 'Source Option 1'},
    {value: '2', label: 'Source Option 2'},
    {value: '3', label: 'Source Option 3'},
  ]

  const dummyDestinationOptions = [
    {value: 'A', label: 'Destination Option A'},
    {value: 'B', label: 'Destination Option B'},
    {value: 'C', label: 'Destination Option C'},
  ]

  const [activeTab, setActiveTab] = useState<string | null>(null)
  const [selectedPlatforms, setSelectedPlatforms] = useState<any[]>([])

  const handleTabChange = (selected: any) => {
    setSelectedPlatforms(selected || [])
    // Set the first platform as the active tab by default
    if (selected && selected.length > 0) {
      setActiveTab(selected[0].value)
    } else {
      setActiveTab(null)
    }
  }

  return (
    <>
      {expandedSections.isCreateOrders && (
        <ExpandableSection
          title='Create Orders'
          stateName='isCreateOrders'
          values={formValues.shipment_setting}
          handleChange={handleChange}
        >
          <div className='separator my-3'></div>
          <div className='container mt-3'>
            <div className='d-flex justify-content-between gap-5'>
              {/* Select Source Platforms */}
              <div className='w-50'>
                <label className='form-label'>Select Source Platforms</label>
                <Select
                  isMulti
                  options={selectOptions}
                  onChange={handleTabChange} // Update tabs on selection change
                />
              </div>
            </div>

            {/* Only show tabs if platforms are selected */}
            {selectedPlatforms.length > 0 && (
              <>
                <div className='separator my-3'></div>
                <div className='card card-custom shadow-lg rounded'>
                  <div className='card-header bg-light border-bottom-0'>
                    <div className='card-toolbar'>
                      <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0'>
                        {selectedPlatforms.map((platform) => (
                          <li className='nav-item' key={platform.value}>
                            <button
                              type='button'
                              className={`nav-link px-4 py-2 me-0 rounded-top fw-bold ${
                                activeTab === platform.value
                                  ? 'bg-primary text-white active-tab'
                                  : 'bg-light text-dark'
                              }`}
                              style={{
                                transition: 'background-color 0.3s ease, color 0.3s ease',
                              }}
                              onClick={() => setActiveTab(platform.value)}
                            >
                              {platform.label}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className='card-body bg-white'>
                    <div className='tab-content'>
                      {selectedPlatforms.map((platform) => (
                        <div
                          key={platform.value}
                          className={`tab-pane fade ${
                            activeTab === platform.value ? 'show active' : ''
                          }`}
                          style={{
                            padding: '20px',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            backgroundColor: '#f9f9f9',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'opacity 0.3s ease',
                          }}
                        >
                          <p className='mb-0 text-muted'>
                            <strong>Content for {platform.label} platform:</strong> Customize this
                            content as per the platform's requirements.
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </ExpandableSection>
      )}
      {expandedSections.isImportOrders && (
        <ExpandableSection
          title='Import Orders'
          stateName='isImportOrders'
          values={formValues.shipment_setting}
          handleChange={handleChange}
        >
          <div className='separator my-3'></div>
          <div className='d-flex justify-content-between gap-4'>
            {/* Sync Start Date */}
            <div className='w-50'>
              <label className='form-label'>Sync Start Date</label>
              <input
                type='datetime-local'
                className='form-control'
                name='sync_start_date'
                value={formValues.order_setting.sync_start_date}
                onChange={handleChange}
              />
            </div>

            {/* ShortCode Input */}
            <ShortCodeInput
              label='Sync Shortcode'
              message='Select a shortcode to use for syncing orders'
              inputWrapperClasses='my-3'
              containerClasses='col-12 col-sm-6'
              labelClass='fs-6 fw-bold'
            />
          </div>
        </ExpandableSection>
      )}

      {expandedSections.isImportShipments && (
        <ExpandableSection
          title='Import Shipments'
          stateName='isImportShipments'
          values={formValues.shipment_setting}
          handleChange={handleChange}
        >
          <div className='separator my-3'></div>
          <div className='w-50'>
            <label className='form-label'>Select Shipment Store</label>
            <Select
              isMulti
              options={selectOptions}
              onChange={(selected) => console.log('Selected Shipment Stores:', selected)}
            />
          </div>
          <div className='separator mt-8'></div>
          <ImportShipmentMapping
            sourceLabel='Source Label'
            sourceOptions={dummySourceOptions}
            destinationLabel='Destination Label'
            destinationOptions={dummyDestinationOptions}
          />
        </ExpandableSection>
      )}

      {expandedSections.isCreateShipments && (
        <ExpandableSection
          title='Create Shipments'
          stateName='isCreateShipments'
          values={formValues.shipment_setting}
          handleChange={handleChange}
        >
          <div className='separator my-3'></div>
          <div className='w-50'>
            <label className='form-label'>Select Shipment Store</label>
            <Select
              isMulti
              options={selectOptions}
              onChange={(selected) => console.log('Selected Shipment Stores:', selected)}
            />
          </div>
          <NumberInputWithArrowsKeyboard />
        </ExpandableSection>
      )}
    </>
  )
}

export default ExpandedSections
