import {configureStore} from '@reduxjs/toolkit'
import authSlice from './slices/authSlice'
import errorSlice from './slices/errorSlice'
import platformSlice from './slices/platformSlice'
import connectedPlatformSlice from './slices/connectedPlatformSlice'
import fetchConnectedPlatformSlice from './slices/fetchConnectedPlatformSlice'
import retrivePlatformDataSlice from './slices/retrivePlatformDataSlice'
import createPlatformSettingsSlice from './slices/createPlatformSettingsSlice'
import orderSlice from './slices/orderSlice'
import productSlice from './slices/productSlice'
import deletePlatformDataSlice from './slices/deletePlatformData.Slice'
import orderDetailSlice from './slices/orderDetailSlice'
import fetchTemuCountrySlice from './slices/fetchTemuCountrySlice'
import fetchUserAccountDataSlice from './slices/fetchUserAccountDataSlice'
import productVarientSlice from './slices/productVarientSlice'
import linkProductVarientSlice from './slices/linkProductVarientSlice'
import productVarientFromMainSlice from './slices/productVarientFromMainSlice'
import uploadSlice from './slices/uploadSlice'
import templateAndPricingSlice from './slices/templateAndPricingSlice'
import platformRepricingSlice from './slices/platformRepricingSlice'
import userInviteSlice from './slices/userInviteSlice'
import customerSlice from './slices/customerSlice'
import platformSettingSlice from './slices/platformSettingSlice'
import pricingSlice from './slices/pricingSlice'
import tourPopupSlice from './slices/tourPopupSlice'
// ...

export const store = configureStore({
  reducer: {
    testReducer: () => {
      return null
    },
    auth: authSlice,
    platform: platformSlice,
    connectedPlatform: connectedPlatformSlice,
    fetchConnectedPlatform: fetchConnectedPlatformSlice,
    retrivePlatformData: retrivePlatformDataSlice,
    createPlatformSettings: createPlatformSettingsSlice,
    deletePlatformData: deletePlatformDataSlice,
    error: errorSlice,
    order: orderSlice,
    productsOfSingleChannel: productSlice,
    orderDetailsData: orderDetailSlice,
    fetchTemuCountry: fetchTemuCountrySlice,
    userAccountData: fetchUserAccountDataSlice,
    productVarientsOfSingleChannel: productVarientSlice,
    linkProductVarient: linkProductVarientSlice,
    productVarientFromMain: productVarientFromMainSlice,
    uploadCsv: uploadSlice,
    templateAndPricing: templateAndPricingSlice,
    platformRepricing: platformRepricingSlice,
    userInvite:userInviteSlice,
    customer:customerSlice,
    platformSetting:platformSettingSlice,
    pricing: pricingSlice,
    tourPopup:tourPopupSlice
    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
