import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../marketConnect/layout/MasterLayout'
import ManageCustomers from '../pages/admin-panel/ManageCustomers'
import Report from '../pages/admin-panel/Report'
import PlatformsList from '../pages/platform/PlatformsList'
import ManagePlatform from '../pages/platform/ManagePlatform'
import SuperadminAccountSetting from '../pages/SuperadminAccountSetting'
// import AvailablePlatforms from '../pages/manage-event/AvailablePlatforms'
// import PlatformEvents from '../pages/manage-event/PlatformEvents'
import ManageCustomPlans from '../pages/custom-plans/ManageCustomPlans'
import PricingPlans from '../pages/custom-plans/PricingPlans'

const SuperAdminRoutes = () => {
  return (
    <Routes>
      <Route>
        <Route index element={<Navigate to={'/dashboard'} />} />
        {/* <Route path='/admin/*' element={<Navigate to={'/customers'} />} /> */}
        <Route path='/dashboard' element={<ManageCustomers />} />
        <Route path='/reports' element={<Report />} />
        <Route path='/reports/:userId' element={<Report />} />
        <Route path='/platforms' element={<PlatformsList />} />
        <Route path='/manage-platform' element={<ManagePlatform />} />
        <Route path='/manage-platform/:platformId' element={<ManagePlatform />} />
        <Route path='/account/settings' element={<SuperadminAccountSetting/>} />
        {/* <Route path='/manage-events' element={<AvailablePlatforms/>} />
        <Route path='/events/:id' element={<PlatformEvents/>} /> */}
        <Route path='/pricing-plans' element={<PricingPlans/>} />
        <Route path='/manage-pricing' element={<ManageCustomPlans/>} />


        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
        <Route path='*' element={<Navigate to='/dashboard' />} />

      </Route>
    </Routes>
  )
}


export {SuperAdminRoutes}
